import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createLink } from './link';
import createDataIdFromObject from './createDataIdFromObject';
import { IS_SERVER } from '../../../../config/environment';

const introspectionToPossibleTypes = (introspectionQueryResultData) => {
    const possibleTypes = {}

    introspectionQueryResultData.__schema.types.forEach(supertype => {
        if (supertype.possibleTypes) {
            possibleTypes[supertype.name] = supertype.possibleTypes.map(subtype => subtype.name)
        }
    })

    return possibleTypes
}

const createClient = ({
    ssrMode = IS_SERVER,
    link,
    dataIdFromObject = createDataIdFromObject(),
    initialState = {},
    cookies = {}
} = {}) => {
    return new ApolloClient({
        ssrMode,
        link: link ? link : createLink(IS_SERVER, cookies),
        defaultOptions: {
            query: {
                errorPolicy: 'all'
            }
        },
        cache: new InMemoryCache({
            // typePolicies: {
            //     Query: {
            //         fields: {
            //             search: {
            //                 keyArgs: false,
            //                 merge(existing, incoming, { args: { offset = 0 }}) {
            //                     console.log('merge =======================')
            //                     console.log(existing, incoming)
            //                     return existing;
            //                 }
            //             }
            //         }
            //     }
            // },
            // possibleTypes: introspectionToPossibleTypes(
            //     {
            //         __schema: {
            //             types: []
            //         }
            //     }
            // ),
            //dataIdFromObject,
            addTypename: true
        }).restore(initialState)
    });
};

export default createClient;
