const INTERSECTION_OPTIONS = {
    rootMargin: '100%'
}

class Observer {
    observer;
    listeners = [];
    isSupported = () => typeof window !== 'undefined' && 'IntersectionObserver' in window;

    observe = (onIntersection, target) => {
        if(target) {
            const handleIntersection = (entries) => entries.filter((entry) => entry.target === target && entry.isIntersecting).forEach(onIntersection);
            this.listeners.push(handleIntersection);
            this.observer = this.create();
            this.observer.observe(target);

            return {
                unobserve: () => {
                    target && this.observer.unobserve(target);
                    this.removeListener(handleIntersection);
                }
            }
        }
    }

    create = () => {
        if(!this.observer) {
            return new IntersectionObserver((entries) => {
                this.listeners.forEach((cb) => cb(entries));
            }, INTERSECTION_OPTIONS);
        }

        return this.observer;
    }

    removeListener = (listener) => {
        this.listeners = this.listeners.filter((cb) => cb !== listener);
    }
}

export default new Observer();
