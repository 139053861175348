import React from "react"
import styled, { css } from "styled-components"
import {
    Button,
    paletteHelper,
    spacingHelper,
    typographyHelper,
} from "../../../../@nitty";
import useSearch from "../../../../util/search/useSearch";

const BUTTON_LABEL = "What are you looking for?"
const BUTTON_PLACEHOLDER = "What?"

const StyledButton = styled(Button).attrs({
    sizeVariant: "medium"
})<{
    showSelected?: boolean;
}>`
    width: 100%;
    justify-content: flex-start;
    padding: ${spacingHelper("extraSmall 0")};
    ${typographyHelper("body01")}
    color: ${paletteHelper("textSecondary")};
    background-color: ${paletteHelper("backgroundHoveredPrimary")};

    border-radius: 1.5rem;
    padding 0.75rem;
    cursor: text;

    &:focus::after {
        border-radius: 2rem;
        top: 0rem;
        right: 0rem;
        bottom: 0rem;
        left: 0rem;
    }

    :hover {
        background-color: ${paletteHelper("borderSecondary")};
        border: none;
    }

    svg {
        flex-shrink: 0;
    }

    span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    @media only screen and (min-width: 880px) {
        span {
            margin-left: ${spacingHelper("medium")};
        }
        :last-child {
            margin-right: ${spacingHelper("small")}
        }
    }
`;

const generateSelectedOptionsText = ({ options, searchQuery }) => {
    const keywords = options
                        .filter(suggestion => suggestion.type === "keyword")
                        .map(keyword => keyword.display.text);

    const suggestionMealTypes = ['tag'];
    const mealTypes = options
                        .filter(suggestion => suggestionMealTypes.includes(suggestion.type))
                        .map(mealType => mealType.display.text);

    const components = [
        ...keywords,
        ...mealTypes
    ];

    const locations = options
                        .filter(suggestion => suggestion.type === "location")
                        .map(location => location.display.short ? location.display.short : location.display.text);

    const optionsComponents = [];

    if(components.length) optionsComponents.push(components.join(', '))
    if(locations.length) optionsComponents.push('near')
    if(locations.length) {
        if("gpsLocationSearch" in searchQuery.filters.location) {
            optionsComponents.push('me')
        }else{
            optionsComponents.push(locations.join(', '))
        }
    }

    const optionsText = `${optionsComponents.join(' ')}`;

    return optionsText.charAt(0).toUpperCase() + optionsText.slice(1);
};

const SuggestionButton = ({
    className,
    onClick,
    selectedOptions,
    ...buttonProps
}) => {
    const { searchQuery } = useSearch();

    return (
        <StyledButton
            className={className}
            aria-label={BUTTON_LABEL}
            onClick={onClick}
            fullWidth
            variant="naked"
            showSelected={false}
            {...buttonProps}
        >
            <span>{selectedOptions.length ? generateSelectedOptionsText({options: selectedOptions, searchQuery}) : BUTTON_PLACEHOLDER}</span>
        </StyledButton>
    )
}

export default SuggestionButton;
