import styled from "styled-components"
import { ButtonSize, ButtonVariant } from "./button.types"
import {
    borderHelper,
    colorHelper,
    backgroundColorHelper,
    paddingHelper,
    focusPositionHelper,
    minSizeHelper,
} from "./styles.helpers"

type ButtonBaseProps = {
    $sizeVariant: ButtonSize
    $fullWidth: boolean
    $variant: ButtonVariant
    $compact: boolean
    disabled: boolean
}

const ButtonBase = styled.button<ButtonBaseProps>`
    ${({ $sizeVariant, theme }) =>
        $sizeVariant === "small"
            ? theme.typography.subtitle02
            : theme.typography.subtitle01}

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    outline: none;
    width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
    border: ${borderHelper()};
    background-color: ${backgroundColorHelper()};
    color: ${colorHelper};
    border-radius: ${({ theme }) => theme.border.radius.medium };
    padding: ${paddingHelper};
    margin: 0;
    cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
    text-decoration: none;
    min-height: ${minSizeHelper};
    min-width: ${minSizeHelper};

    @media (hover: hover) {
        &:hover {
            background-color: ${backgroundColorHelper("hover")};
            border: ${borderHelper("hover")}
        }
    }

    &:focus::after {
        content: "";
        position: absolute;
        top: ${focusPositionHelper};
        right: ${focusPositionHelper};
        bottom: ${focusPositionHelper};
        left: ${focusPositionHelper};
        border: ${borderHelper("focus")};
        border-radius: ${({ theme, $variant }) => 
            $variant === "naked"
                ? theme.border.radius.medium 
                : theme.border.radius.large };
    }

    &:focus-visible::after {
        content: "";
        position: absolute;
        top: ${focusPositionHelper};
        right: ${focusPositionHelper};
        bottom: ${focusPositionHelper};
        left: ${focusPositionHelper};
        border: ${borderHelper("focus")};
        border-radius: ${({ theme, $variant }) =>
        $variant === "naked"
            ? theme.border.radius.medium
            : theme.border.radius.large };
    }

    &:focus:not(:focus-visible)::after {
        border: none;
    }
`

export default ButtonBase;