import { DefaultTheme } from 'styled-components';
import {TypographyTheme, TypographyVariant, StrongVariant} from '../components/nitty/types';
import { replaceToken } from '../private/helpers';

type HelperProps = {
    theme: DefaultTheme;
};

export const typographyHelper = (key: keyof TypographyTheme) => ({
    theme
}: HelperProps): TypographyVariant | StrongVariant => theme.typography[key]

export const paletteHelper = (key: string) => ({
    theme,
}: HelperProps): string => replaceToken(theme.palette, key);


export const spacingHelper = (key: string) => ({
    theme,
}: HelperProps): string => replaceToken(theme.spacing, key);

