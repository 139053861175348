import { useEffect } from "react"
import {
    useFloating,
    flip,
    getOverflowAncestors,
    Placement,
    Middleware
} from "@floating-ui/react-dom";
import { debounce } from "../private/helpers";
import { ResizeObserver } from "resize-observer";

type PopperProps = {
    placement?: Placement
    middleware?: Middleware[]
    mounted: boolean
}

const usePopper = <ReferenceType extends HTMLElement>({
    placement = "bottom",
    middleware = [flip()],
    mounted
}: PopperProps) => {
    const {
        x,
        y,
        reference,
        floating,
        strategy,
        update,
        refs,
        placement: internalPlacement
    } = useFloating<ReferenceType>({
        placement,
        middleware
    })

    useEffect(() => {
        const referenceRef = refs.reference.current;
        const floatingRef = refs.floating.current;
        if(!mounted || !floatingRef || !referenceRef) {
            return
        }

        const isElement = (
            item: Element | Window | VisualViewport
        ): item is Element => "className" in item

        const parents = [...getOverflowAncestors(referenceRef)]
        const elementsToObserve = [
            ...parents.filter(isElement),
            floatingRef,
            referenceRef
        ]

        const { debounceFunc } = debounce(update)

        parents.forEach(parent => {
            parent.addEventListener("scroll", debounceFunc)
        })

        const resizeObserver = new ResizeObserver(update);
        elementsToObserve.forEach(parent => {
            resizeObserver.observe(parent);
        })
        window.addEventListener("resize", debounceFunc);

        return () => {
            parents.forEach(parent => {
                parent.removeEventListener("scroll", debounceFunc)
            })
            elementsToObserve.forEach(parent => {
                resizeObserver.unobserve(parent)
            })
            window.removeEventListener("scroll", debounceFunc)
        }
    }, [mounted, update, refs.floating, refs.reference])

    return {
        popperRef: floating,
        popperStyle: {
            position: strategy,
            top: y ?? "0",
            left: x ?? "0"
        },
        placement: internalPlacement,
        referenceRef: reference,
        refs
    }
}

export default usePopper;