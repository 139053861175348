import { LabelSizeVariant } from "../../components";
import { nanoid } from "nanoid/non-secure"
import { useEffect, useRef } from "react";

type ComponentToLabelSize = {
    small: LabelSizeVariant
    medium: LabelSizeVariant
    large: LabelSizeVariant
}

export const componentToLabelSize: ComponentToLabelSize = {
    small: "small",
    medium: "large",
    large: "large"
}

export const useAutoId = (id?: string | undefined) => useRef(id || nanoid(5)).current

export const replaceToken = (tokens: any, value: string) => {
    const tokenKeys = Object.keys(tokens);
    return tokenKeys
        .filter((key) => value.includes(key))
        .reduce(
            (v, t) => v.replace(new RegExp(`\\b${t}\\b`, 'g'), tokens[t]),
            value,
        );
};

export const pxToRem = (px: number) => `${px/16}rem`

export const useUpdateEffect = (callback: () => any, deps: any[]) => {
    const hasMount = useRef(false);
    
    useEffect(() => {
        if(hasMount.current) {
            callback()
        }else{
            hasMount.current = true
        }
    }, deps)
}

export const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}


type DebounceReturn<F extends (...args: any[]) => any> = {
    debounceFunc: (...args: Parameters<F>) => void
    cancel: () => void
}

export const debounce = <F extends (...args: any[]) => any>(
    func: F,
    waitFor = 200
): DebounceReturn<F> => {
    let timeout: number | undefined;

    return {
        debounceFunc: (...args: Parameters<F>): void => {
            if(timeout) {
                clearTimeout(timeout);
            }

            timeout = window.setTimeout(() => {
                func(...args);
            }, waitFor)
        },
        cancel: () => {
            if(timeout) {
                clearTimeout(timeout)
            }
        }
    }
}
