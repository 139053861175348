import React from "react";
import forwardRef from "../../../private/forwardRef";
import { useSelect } from "downshift"
import composeRefs from "@seznam/compose-react-refs"
import TextInputBase from "../../text-input/text-input-base"
import { ArrowDownMd } from "../../icons"
import { componentToLabelSize, useAutoId } from "../../../private/helpers"
import {
    StyledLabel,
    ChevronWrapper,
    IconWrapper,
    DisplayContainer,
    ComponentContainer
} from "./styles"
import { ListboxWrapper, Listbox } from "../../autocomplete/styles"
import { ListboxOption } from "../../autocomplete"
import {
    SelectMapOptionToListNode,
    SelectOption,
    SelectProps,
    SelectSize
} from './select.types'
import usePopper from "../../../hooks/usePopper"

const defaultSelectedOptionDisplayText = (selectedItem: SelectOption) => selectedItem.label;

const defaultMapOptionToListNode: SelectMapOptionToListNode = ({
    option,
    sizeVariant,
    isActive
}) => (
    <ListboxOption
        sizeVariant={sizeVariant}
        isActive={isActive}
        data-always-inside
    >
        {option.label}
    </ListboxOption>
);

const defaultProps = {
    placeholder: "Please select",
    onSelectedOptionChange: () => { },
    sizeVariant: "large" as SelectSize,
    visibleOptionsToDisplay: 5,
    renderDisplayText: defaultSelectedOptionDisplayText,
    helpMessage: "",
    errorMessage: "",
    disabled: false,
    hideLabel: false,
    mapOptionToListNode: defaultMapOptionToListNode
}

const Select = forwardRef<SelectProps, "div">((props, externalRef) => {
    const {
        placeholder,
        options,
        label,
        name,
        selectedOption,
        onSelectedOptionChange,
        sizeVariant,
        defaultSelectedOption,
        disabled,
        icon,
        id,
        listboxMaxHeight,
        visibleOptionsToDisplay,
        mapOptionToListNode,
        renderDisplayText,
        hideLabel,
        helpMessage,
        errorMessage,
        dangerouslySetClassNames,
        className,
        style
    } = { ...defaultProps, ...props };

    const autoId = useAutoId(id);

    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
        selectItem
    } = useSelect({
        items: options,
        id: autoId,
        itemToString: item => (item ? item.label : ""),
        onSelectedItemChange: ({ selectedItem }) => onSelectedOptionChange?.(selectedItem),
        initialSelectedItem: defaultSelectedOption,
        ...(selectedOption !== undefined && { selectedItem: selectedOption })
    })

    const [focused, setFocused] = React.useState(false);

    const { popperRef, popperStyle, placement, refs } = usePopper({
        mounted: isOpen
    })

    return (
        <ComponentContainer className={className} style={style}>
            <StyledLabel
                sizeVariant={componentToLabelSize[sizeVariant]}
                disabled={disabled}
                hideLabel={hideLabel}
                className={dangerouslySetClassNames?.label}
                dangerouslySetClassNames={dangerouslySetClassNames?.labelClassNames}
                {...getLabelProps}
            >
                {label}
            </StyledLabel>
            <div className={dangerouslySetClassNames?.selectContainer}>
                <TextInputBase
                    dangerouslySetClassNames={{
                        helpMessage: dangerouslySetClassNames?.helpMessage,
                        errorMessage: dangerouslySetClassNames?.errorMessage,
                        scrollableArea: dangerouslySetClassNames?.scrollableArea,
                        inputBorder: dangerouslySetClassNames?.inputBorder
                    }}
                    id={autoId}
                    textInputBorderProps={{
                        ...getToggleButtonProps({
                            ref: composeRefs(refs.reference, externalRef),
                        }),
                        as: "button",
                        type: "button",
                        onFocus: () => setFocused(true),
                        onBlur: () => setFocused(false),
                        focused: focused || isOpen,
                        squaredCornerPosition: isOpen ? placement : "none",
                        style: { outline: "none", width: "100%", color: "inherit" }
                    }}
                    beforeNode={
                        icon && (
                            <IconWrapper
                                aria-hidden="true"
                                sizeVariant={sizeVariant}
                                className={dangerouslySetClassNames?.iconWrapper}
                                disabled={disabled}
                            >
                                {icon}
                            </IconWrapper>
                        )
                    }
                    afterNode={
                        <ChevronWrapper
                            aria-hidden="true"
                            sizeVariant={sizeVariant}
                            isOpen={isOpen}
                            disabled={disabled}
                            className={dangerouslySetClassNames?.chevronWrapper}
                        >
                            <ArrowDownMd />
                        </ChevronWrapper>
                    }
                    renderInput={() => (
                        <DisplayContainer
                            sizeVariant={sizeVariant}
                            className={dangerouslySetClassNames?.displayContainer}
                            isPlaceholder={selectedItem?.label === undefined}
                            disabled={disabled}
                        >
                            <span className={dangerouslySetClassNames?.displayText}>
                                {(selectedItem && renderDisplayText(selectedItem)) ||
                                    placeholder}
                            </span>
                            <input
                                type="hidden"
                                readOnly
                                name={name || autoId}
                                value={selectedItem?.value || ""}
                            />
                        </DisplayContainer>
                    )}
                    sizeVariant={sizeVariant}
                    disabled={disabled}
                    helpMessage={helpMessage}
                    errorMessage={errorMessage}
                />

                {isOpen ? (
                    <ListboxWrapper
                        ref={popperRef}
                        popperStyle={popperStyle}
                        className={dangerouslySetClassNames?.listboxWrapper}
                    >
                        <Listbox
                            visible={true}
                            sizeVariant={sizeVariant}
                            visibleOptionsToDisplay={visibleOptionsToDisplay}
                            listboxMaxHeight={listboxMaxHeight}
                            placement={placement}
                            isErrored={!!errorMessage}
                            className={dangerouslySetClassNames?.listbox}
                            {...getMenuProps({
                                onKeyDown: e => {
                                    if (e.key === "Tab" && highlightedIndex !== -1) {
                                        selectItem(options[highlightedIndex])
                                    }
                                }
                            })}
                        >
                            {options.map((item, index) =>
                                React.cloneElement(
                                    mapOptionToListNode({
                                        option: item,
                                        sizeVariant,
                                        isActive: highlightedIndex === index
                                    }),
                                    getItemProps({
                                        item,
                                        index,
                                        key: `${item.value}`,
                                        className: dangerouslySetClassNames?.listboxOption
                                    })
                                )
                            )}
                        </Listbox>
                    </ListboxWrapper>
                ) : (
                    <ListboxWrapper
                        popperStyle={{}}
                        className={dangerouslySetClassNames?.listboxWrapper}
                    >
                        <Listbox
                            visible={false}
                            className={dangerouslySetClassNames?.listbox}
                            {...getMenuProps({
                                onKeyDown: e => {
                                    if (e.key === "Tab" && highlightedIndex !== -1) {
                                        selectItem(options[highlightedIndex])
                                    }
                                }
                            })}
                        />
                    </ListboxWrapper>
                )}
            </div>
        </ComponentContainer>
    )
})

Select.displayName = "Select";

export default Select;