import React from "react"
import styled from "styled-components"
import Text from '../text';
import { As } from "../../private/forwardRef/forwardRef.types"
import { LabelProps, LabelSizeVariant } from "./label.types"
import forwardRef from "../../private/forwardRef"

const VisuallyHiddenLabel = styled.label`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
`

type VisibleLableProps = {
    forwardedAs: As
}

const VisibleLabel = styled(Text).attrs<VisibleLableProps>({
    forwardedAs: "label"
})`
    color: ${({ disabled, theme }) => disabled && theme.palette.textDisabled};
`

const defaultProps = {
    sizeVariant: "large" as LabelSizeVariant,
    disabled: false,
    hideLabel: false
}

const Label = forwardRef<LabelProps, "label">((props, ref) => {
    const {
        children,
        sizeVariant,
        hideLabel,
        className,
        style,
        dangerouslySetClassNames,
        ...rest
    } = { ...defaultProps, ...props }

    return hideLabel ? (
        <VisuallyHiddenLabel ref={ref} {...rest}>
            {children}
        </VisuallyHiddenLabel>
    ) : (
        <div className={className} style={style}>
            <VisibleLabel
                ref={ref}
                variant={sizeVariant === "large" ? "subtitle01" : "subtitle02"}
                className={dangerouslySetClassNames?.label}
                {...rest}
            >
                {children}
            </VisibleLabel>
        </div>
    )
})

Label.displayName = "Label"

export { VisuallyHiddenLabel }
export default Label;