import React, { useContext, useEffect, useState } from 'react';
import { fetchingData } from '../util/appEvents';
import { Layout } from '../@nearbites.com/design';
import { MEAL_DETAILS_SCREEN } from '../router/types';
import styled from 'styled-components';
import { MealSpriteSheet } from '../components/sprites';
import SearchBar from '../components/search-bar';
import useSearch from '../util/search/useSearch';
import AppContext from '../util/AppContext';
import { ChannelType } from '../@nearbites.com/query';

const handleIOSEventBubbling = () => { };

const getLayout = screenName => 'DEFAULT';

const AppContainer = styled.div`
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

export default function Root({ children, screenName, history }) {
    const { isLoading, setIsLoading, location } = useContext(AppContext);

    useEffect(() => {
        fetchingData.on('start', () => {
            setIsLoading(true);
        });

        fetchingData.on('end', () => {
            setIsLoading(false);
        });
    }, []);

    return (
        <AppContainer id="app-container" onClick={handleIOSEventBubbling}>
            <Layout
                isLoading={isLoading}
                layout={getLayout(screenName)}
                elements={{
                    searchHeader: <SearchBar channels={Object.values(ChannelType)} />
                }}
            >
                <MealSpriteSheet />
                {children}
            </Layout>
        </AppContainer>
    );
}
