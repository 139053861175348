import React, { useContext } from "react";
import styled from "styled-components";
import TabsContext from "./tabs-context";
import { spacingHelper } from "../../utils";
import forwardRef from "../../private/forwardRef";
import { TabBarProps } from "./tabs.types"

type BarProps = {
    hideTabBarLine: boolean
}

const Bar = styled.div<BarProps>`
    border-bottom: ${({ theme, hideTabBarLine }) => !hideTabBarLine && `${theme.border.width.thin} solid ${theme.palette.borderSecondary}`};
    margin-bottom: ${spacingHelper("large")};
`

const TabBar = forwardRef<TabBarProps, "div">(({ children, ...props}, ref) => {
    const { hideTabBarLine } = useContext(TabsContext)

    return (
        <Bar ref={ref} hideTabBarLine={hideTabBarLine} {...props}>
            {children}
        </Bar>
    )
})

TabBar.displayName = "TabBar"

export default TabBar;