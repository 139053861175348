import React from "react";
import styled from "styled-components";
import { paletteHelper } from "../../../@nitty";
import headerHeights from "./headerHeights";

const LogoLink = styled.a`
    height: ${headerHeights.medium};
    display: inline-flex;
    align-items: center;

    .logo-full {
        display: none;
    }

    .logo-small {
        padding-right: 1rem;
    }

    .logo-full,
    .logo-small {
        height: 2.25rem;
    }

    .icon,
    .text {
        fill: ${paletteHelper("textBrand")};
    }

    @media only screen and (min-width: 880px) {
        .logo-small {
            display: none;
        }
        .logo-full {
            display: block;
        }
    }
`;

const NearbitesLogo = () => {
    return (
        <LogoLink title="Nearbites" href="/">
            <svg className="logo-full" viewBox="0 0 480 97">
                <path className="icon" d="M78,35.3a7,7,0,0,1-9.2-.6l-.2-.2a4.35,4.35,0,0,1-.6.7,7.85,7.85,0,0,1-11,0,8,8,0,0,1-1.8-8.3A7.87,7.87,0,0,1,46.8,14c.2-.2.5-.4.7-.6a7.06,7.06,0,0,1,0-9.9l.3-.3a41.85,41.85,0,0,0-7.2-.7A37.79,37.79,0,0,0,3,40.3C3,56.6,25.9,86.1,36,97a6.35,6.35,0,0,0,9.3,0c10.1-10.9,33-40.4,33-56.6A42.36,42.36,0,0,0,78,35.3ZM40.6,53.9A8.9,8.9,0,1,1,49.5,45,8.92,8.92,0,0,1,40.6,53.9Z" />
                <g className="text">
                    <path d="M104.05,70V38.45h3.66v5.68h.38a9.57,9.57,0,0,1,4-4.57,12.64,12.64,0,0,1,6.53-1.64q5.28,0,8.17,3c1.94,2,2.9,4.87,2.9,8.54V70h-3.83V50.46q0-9.09-8.24-9.08a9.54,9.54,0,0,0-7.1,2.71,9.71,9.71,0,0,0-2.68,7.16V70Z" />
                    <path d="M161.06,62.21H165a10.71,10.71,0,0,1-4.63,6.09,15.16,15.16,0,0,1-8.41,2.23,12.93,12.93,0,0,1-10.31-4.41q-3.81-4.41-3.81-11.88t3.82-11.88a12.88,12.88,0,0,1,10.27-4.44,12.46,12.46,0,0,1,9.93,4.19q3.65,4.19,3.64,11.43v1.69h-23.7v.18a13.07,13.07,0,0,0,2.91,8.53A9.3,9.3,0,0,0,152,67.1a11.83,11.83,0,0,0,5.68-1.27A7.39,7.39,0,0,0,161.06,62.21Zm-9.2-20.89a9.25,9.25,0,0,0-7.06,2.94,12,12,0,0,0-3,7.81h19.65a11.5,11.5,0,0,0-2.68-7.82A8.8,8.8,0,0,0,151.86,41.32Z" />
                    <path d="M182.57,70.53A11.38,11.38,0,0,1,174.91,68a8.37,8.37,0,0,1-3-6.72,7.86,7.86,0,0,1,2.93-6.46c1.95-1.57,4.77-2.49,8.44-2.74l9.87-.64v-3a6.72,6.72,0,0,0-2-5.22,8.45,8.45,0,0,0-5.85-1.81,10.56,10.56,0,0,0-5.46,1.3,5.61,5.61,0,0,0-2.77,3.59h-3.9a8.61,8.61,0,0,1,3.91-6,14.44,14.44,0,0,1,8.34-2.29q5.53,0,8.57,2.74a9.84,9.84,0,0,1,3,7.69V70h-3.69V64.58H193A10.35,10.35,0,0,1,188.81,69,12.46,12.46,0,0,1,182.57,70.53Zm.64-3.4a10.26,10.26,0,0,0,7.11-2.56,8.17,8.17,0,0,0,2.85-6.32V54.56l-9.34.67q-8,.57-7.94,6a5.2,5.2,0,0,0,2.05,4.31A8.28,8.28,0,0,0,183.21,67.13Z" />
                    <path d="M206.74,70V38.45h3.66v5.18h.38A7.41,7.41,0,0,1,214,39.49a10,10,0,0,1,5.57-1.57,13.52,13.52,0,0,1,3,.26V42a12.84,12.84,0,0,0-3.43-.38A8.19,8.19,0,0,0,213,44.12a8.44,8.44,0,0,0-2.44,6.19V70Z" />
                    <path d="M243.94,70.53a11.9,11.9,0,0,1-6.5-1.82,11.09,11.09,0,0,1-4.28-5h-.38V70h-3.66V25.88H233V44.57h.35a10.43,10.43,0,0,1,4.19-4.86,11.94,11.94,0,0,1,6.44-1.79,11.8,11.8,0,0,1,9.64,4.45q3.63,4.46,3.63,11.84T253.57,66A11.8,11.8,0,0,1,243.94,70.53Zm6.6-25.7a8.92,8.92,0,0,0-7.39-3.45,9.1,9.1,0,0,0-7.47,3.5q-2.81,3.49-2.81,9.33t2.81,9.33a9.12,9.12,0,0,0,7.47,3.5,8.91,8.91,0,0,0,7.39-3.47c1.81-2.32,2.72-5.44,2.72-9.39S252.35,47.14,250.54,44.83Z" />
                    <path d="M269.61,30.58a2.78,2.78,0,0,1-2,.84,2.74,2.74,0,0,1-2-.85,2.79,2.79,0,0,1-.84-2,2.87,2.87,0,0,1,2.85-2.84,2.78,2.78,0,0,1,2,.84,2.71,2.71,0,0,1,.85,2A2.77,2.77,0,0,1,269.61,30.58ZM265.68,70V38.45h3.84V70Z" />
                    <path d="M281.56,30.36h3.84v8.09h7.5V41.7h-7.5V61.27a6.18,6.18,0,0,0,1.17,4.2,4.94,4.94,0,0,0,3.84,1.31q1.38,0,2.34-.09V70a15.78,15.78,0,0,1-2.75.21q-4.54,0-6.49-1.86t-1.95-6.17V41.7h-5.1V38.45h5.1Z" />
                    <path d="M321.87,62.21h3.9a10.71,10.71,0,0,1-4.63,6.09,15.16,15.16,0,0,1-8.41,2.23,12.93,12.93,0,0,1-10.31-4.41q-3.81-4.41-3.81-11.88t3.83-11.88a12.84,12.84,0,0,1,10.26-4.44,12.46,12.46,0,0,1,9.93,4.19q3.64,4.19,3.64,11.43v1.69h-23.7v.18a13.07,13.07,0,0,0,2.91,8.53,9.3,9.3,0,0,0,7.34,3.16,11.83,11.83,0,0,0,5.68-1.27A7.39,7.39,0,0,0,321.87,62.21Zm-9.2-20.89a9.25,9.25,0,0,0-7.06,2.94,12,12,0,0,0-3,7.81h19.65a11.5,11.5,0,0,0-2.68-7.82A8.8,8.8,0,0,0,312.67,41.32Z" />
                    <path d="M333.86,46.8a7.74,7.74,0,0,1,3.29-6.33,12.85,12.85,0,0,1,8.1-2.55,13.41,13.41,0,0,1,7.91,2.21,8.46,8.46,0,0,1,3.66,6H353a5.67,5.67,0,0,0-2.62-3.56,9.56,9.56,0,0,0-5.2-1.33,8.93,8.93,0,0,0-5.31,1.55,4.64,4.64,0,0,0-2.19,3.9,3.82,3.82,0,0,0,1.53,3.14,12.36,12.36,0,0,0,4.74,2l4.34,1a15.63,15.63,0,0,1,6.85,3.12,6.92,6.92,0,0,1,2.2,5.38,8,8,0,0,1-3.49,6.65,14.09,14.09,0,0,1-8.7,2.61,14.8,14.8,0,0,1-8.49-2.27,8.38,8.38,0,0,1-3.81-6.17h4a6.28,6.28,0,0,0,2.84,3.78,10.37,10.37,0,0,0,5.6,1.38,9.87,9.87,0,0,0,5.8-1.62,4.81,4.81,0,0,0,2.32-4.07,4.16,4.16,0,0,0-1.5-3.32,11.32,11.32,0,0,0-4.69-2l-4.77-1.14a14.46,14.46,0,0,1-6.53-3A6.82,6.82,0,0,1,333.86,46.8Z" />
                </g>
            </svg>
            <svg className="logo-small" viewBox="0 0 26 32">
                <path className="icon" d="M25.5567 11.6454C25.1127 11.9856 24.5602 12.153 24.002 12.1166C23.4438 12.0802 22.9178 11.8424 22.5217 11.4474L22.4557 11.3814C22.3981 11.4652 22.3317 11.5426 22.2578 11.6124C21.7735 12.0879 21.122 12.3543 20.4433 12.3543C19.7647 12.3543 19.1131 12.0879 18.6289 11.6124C18.2829 11.2577 18.0436 10.8128 17.9386 10.3286C17.8336 9.84437 17.867 9.34035 18.0351 8.87422C17.4801 9.08759 16.8688 9.10405 16.3032 8.92083C15.7376 8.73762 15.252 8.36584 14.9276 7.86762C14.6032 7.36941 14.4596 6.77494 14.5208 6.18357C14.582 5.5922 14.8443 5.03975 15.2639 4.61855C15.3299 4.55257 15.4289 4.48659 15.4949 4.42061C15.0665 3.98501 14.8265 3.39855 14.8265 2.78762C14.8265 2.1767 15.0665 1.59024 15.4949 1.15463L15.5938 1.05567C14.8102 0.910277 14.0155 0.833012 13.2186 0.824738C9.92248 0.84131 6.76708 2.16254 4.44254 4.49944C2.11801 6.83633 0.81354 9.9987 0.814454 13.2948C0.814454 18.6722 8.36909 28.4041 11.7011 32C11.8971 32.2108 12.1345 32.379 12.3984 32.4939C12.6624 32.6089 12.9472 32.6682 13.2351 32.6682C13.523 32.6682 13.8078 32.6089 14.0717 32.4939C14.3356 32.379 14.573 32.2108 14.7691 32C18.1011 28.4041 25.6557 18.6722 25.6557 13.3278C25.6566 12.7655 25.6235 12.2037 25.5567 11.6454ZM13.2186 17.7814C12.6379 17.7814 12.0702 17.6092 11.5874 17.2866C11.1045 16.964 10.7282 16.5054 10.506 15.9689C10.2838 15.4324 10.2256 14.8421 10.3389 14.2726C10.4522 13.703 10.7318 13.1799 11.1425 12.7692C11.5531 12.3586 12.0762 12.079 12.6458 11.9657C13.2153 11.8524 13.8057 11.9105 14.3422 12.1328C14.8787 12.355 15.3372 12.7313 15.6598 13.2142C15.9825 13.697 16.1547 14.2647 16.1547 14.8454C16.1529 15.6235 15.843 16.3693 15.2928 16.9196C14.7425 17.4698 13.9967 17.7797 13.2186 17.7814Z" fill="#F09204"/>
            </svg>
        </LogoLink>
    )
}

export default NearbitesLogo;
