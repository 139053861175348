import { SearchQuery, Channel } from "../../@nearbites.com/query";

export enum SearchQueryActionType {
    UpdateChannel,
    UpdateLocation,
    UpdateKeywords,
    UpdateSearchQuery
}

export type SearchQueryAction =
    | {
        type: SearchQueryActionType.UpdateChannel;
        channel: Channel;
    }
    | {
        type: SearchQueryActionType.UpdateLocation;
        locations: string[];
    }
    | {
        type: SearchQueryActionType.UpdateKeywords;
        keywords: string[];
    }
    | {
        type: SearchQueryActionType.UpdateSearchQuery;
        searchQuery: SearchQuery;
    }
