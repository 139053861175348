import React from "react";
import forwardRef from "../../../private/forwardRef";
import { IconProps } from '../icon.types';
import { nanoid } from "nanoid/non-secure"

const ArrowDownMd = forwardRef<IconProps, "svg">(({ className, ...props}, ref) => {
    const titleId = props?.id ? `${props.id}-title` : nanoid(5);
    return (
        <svg
            className={["Nitty__Icon--md", className].filter(Boolean).join(" ")}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={ref}
            aria-labelledby={props?.title ? titleId : undefined}
            {...props}
        >
            {props?.title && <title id={titleId}>{props.title}</title>}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="currentColor"
                d="M8.12 9.29L12 13.17l3.88-3.88a.996.996 0 1 1 1.41 1.41l-4.59 4.59a.996.996 0 0 1-1.41 0L6.7 10.7a.996.996 0 0 1 0-1.41c.39-.38 1.03-.39 1.42 0z"
            />
        </svg>
    )
})

ArrowDownMd.displayName = "ArrowDownMd"

export default ArrowDownMd;
