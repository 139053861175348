export const DEBUG = process.env.DEBUG || false;
export const APP_VERSION = process.env.APP_VERSION || 'not found';
export const NODE_ENV = process.env.NODE_ENV || 'production';
export const IS_DEVELOPMENT = NODE_ENV === 'development';
export const IS_PRODUCTION = NODE_ENV === 'production';
export const API_HOST_SERVER = process.env.API_HOST_SERVER || 'https://api.nearbites.com';
export const API_HOST_CLIENT = process.env.API_HOST_CLIENT || 'https://api.nearbites.com';
export const SUGGEST_API = `${API_HOST_CLIENT}/suggestions`;
export const IS_CLIENT = typeof window != 'undefined';
export const IS_SERVER = typeof window == 'undefined';
export const SITE_NAME = 'Nearbites';
export const SITE_URL = 'https://nearbites.com';
export const IOS_APP_ID = '1504211438';
export const GOOGLE_APP_ID = 'com.nearbites';
export const FACEBOOK_APP_ID = 'REPLACE';