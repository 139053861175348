import { H2, Divider, spacingHelper } from "../../../../../@nitty";
import React, { FC } from "react";
import styled from "styled-components";

type Props = {
    title: string;
    showDivider?: boolean;
}

const StyledDivider = styled(Divider)`
    margin: ${spacingHelper("extraLarge 0 0 0")};
`;

export const StyledTitle = styled(H2)`
    ${({ theme }) => theme.typography.title03};
    margin: ${spacingHelper("0 0 medium 0")};
    padding-top: ${spacingHelper("large")}
`

const FilterWrapper: FC<Props> = ({
    title,
    children,
    showDivider = true
}) => {
    const ref = React.useRef<HTMLInputElement>(null);

    return (
        <>
            <StyledTitle ref={ref}>{title}</StyledTitle>
            {children}
            {showDivider && <StyledDivider />}
        </>
    )
}

export default FilterWrapper;
