import React from "react";
import forwardRef from "../../../private/forwardRef";
import { IconProps } from '../icon.types';
import { nanoid } from "nanoid/non-secure"

const FilterMd = forwardRef<IconProps, "svg">(({ className, ...props}, ref) => {
    const titleId = props?.id ? `${props.id}-title` : nanoid(5);
    return (
        <svg
            className={["Nitty__Icon--md", className].filter(Boolean).join(" ")}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={ref}
            aria-labelledby={props?.title ? titleId : undefined}
            {...props}
        >
            {props?.title && <title id={titleId}>{props.title}</title>}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="currentColor"
                d="M11 18h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1zm4 6h10c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1 .45-1 1s.45 1 1 1z"
            />
        </svg>
    )
})

FilterMd.displayName = "FilterMd"

export default FilterMd;
