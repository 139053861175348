import React, { useEffect } from 'react';
import useLazy from './useLazy';
import { getSrcFromTemplatedUrl } from '../../util/getSrcFromTemplatedUrl';

export const TemplatedLazyImage = (props) => {
    const { lazy, placeholder, templatedUrl, size, defaultJpeg, avif, options, ...rest } = props;
    const [ready, setRef, reset] = useLazy();
    const renderSrc = !lazy || (lazy && ready);
    const format = defaultJpeg ? 'jpeg,r=255,g=255,b=255': null;
    const src = getSrcFromTemplatedUrl({ templatedUrl, size, options, format});

    useEffect(() => {
        if(ready) {
            reset();
        }
    }, [src]);

    return (
        <picture>
            {renderSrc && avif && (
                <source srcSet={getSrcFromTemplatedUrl({ templatedUrl, size, options, format: 'avif' })} type="image/avif" />
            )}
            {renderSrc && (
                <source srcSet={getSrcFromTemplatedUrl({ templatedUrl, size, options, format: 'webp' })} type="image/webp" />
            )}
            <img {...rest} ref={setRef} src={renderSrc ? src : placeholder} />
        </picture>
    )
}
