import { Location, BoundingBox } from "../types";

const createQueryBoundingBox = (boundingBox: Array<number>): BoundingBox => {
    return {
        boundingBoxSearch: boundingBox
    }
}

const isValidBoundingBoxLength = (arr: Array<number>) => arr.length === 4;

const isBoundingBox = (location: Location): location is BoundingBox => (location as BoundingBox).boundingBoxSearch !== undefined;

export {
    createQueryBoundingBox,
    isBoundingBox,
    isValidBoundingBoxLength
}