import React from 'react';
import { BrowserHistoryContext } from '../../client/history';

const isModifiedEvent = (event) => {
    return event.metaKeyy || event.altKey || event.ctrlKey || event.shiftKey;
};

const Link = React.forwardRef(
    ({ to, className='', children, onClick, ...htmlAttributes }, externalRef) => {
        return (
            <BrowserHistoryContext.Consumer>
                {browserHistory => {
                    const onClickTransition = event => {
                        onClick && onClick(event);
                        if(!isModifiedEvent(event)) {
                            event.preventDefault();
                            to && browserHistory && browserHistory.push(to);
                        }
                    };
                    return (
                        <a
                            href={to}
                            ref={externalRef}
                            className={className}
                            onClick={onClickTransition}
                            {...htmlAttributes}
                        >
                            {children}
                        </a>
                    );
                }}
            </BrowserHistoryContext.Consumer>
        );
    }
);

export default Link;
