import React, { FunctionComponent, useState, useEffect } from "react";
import styled from "styled-components";
import { paletteHelper, spacingHelper, Button } from "../../../../@nitty";
import {
    PriceRangeFilter,
    FilterWrapper,
    ClearButton
} from './components';
import isEqual from "./isEqual";
import { updateSearchQuery, Channel, SearchQuery, QueryFilters, ChannelViewConfiguration } from "../../../../@nearbites.com/query";

export type FiltersProps = {
    channels: Array<Channel>;
    searchQuery: SearchQuery,
    onSubmitSearch: (searchQuery: SearchQuery) => void;
    onSearchQueryUpdate?: (searchQuery: SearchQuery) => void;
}

const FiltersWrapper = styled.div`
    line-height: 1.5;
    background-color: ${paletteHelper("backgroundPrimary")};
`

const Wrapper = styled.div`
    padding: ${spacingHelper("0 medium large medium")};
    position: relative;
    ${({ theme })  => theme?.layoutBreakpoints?.large} {
        padding: ${spacingHelper("0 threeExtraLarge large threeExtraLarge")};
    }
`

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: ${paletteHelper("backgroundPrimary")};
    position: sticky;
    bottom:0;
    padding: ${spacingHelper("medium")};
    border-top: 1px solid ${paletteHelper("borderSecondary")};
`

const Filters: FunctionComponent<FiltersProps> = ({
    searchQuery: initialSearchQuery,
    onSubmitSearch,
    onSearchQueryUpdate,
    channels
}) => {
    const [searchQuery, setSearchQuery] = useState<SearchQuery>(initialSearchQuery);

    useEffect(() => {
        if(!isEqual(initialSearchQuery.filters, searchQuery.filters)) {
            setSearchQuery(prevState => ({
                ...prevState,
                filters: { ...initialSearchQuery.filters }
            }))
        }
    }, [initialSearchQuery.filters])

    const setFilters = (filters: Partial<QueryFilters>) => {
        const newSearchQuery = {
            ...searchQuery,
            filters: {
                ...searchQuery.filters,
                ...filters
            }
        }
        setSearchQuery(newSearchQuery);
        onSearchQueryUpdate?.(newSearchQuery);
    }

    const handleFilterChange = <K extends keyof QueryFilters>(filterName: K) => (newValue: QueryFilters[K]) => {
        setFilters({ [filterName]: newValue });
    };

    const handleSubmitNewFilters = () => {
        const newSearchQuery = updateSearchQuery({
            ...searchQuery.filters,
            channel: searchQuery.channel,
            page: 1
        })(searchQuery);
        onSubmitSearch(newSearchQuery);
    }

    const {
        filterOptions
    } = ChannelViewConfiguration[searchQuery.channel];
    const { filters } = searchQuery;

    return (
        <FiltersWrapper>
            {channels.length > 1 ? (
                <div>Channel Selector</div>
            ): null}
            <Wrapper>
                <FilterWrapper
                    title="Price"
                    showDivider={false}
                >
                    <PriceRangeFilter
                        price={filterOptions.price}
                        label={filterOptions.priceLabel}
                        range={filters.priceRange}
                        onChange={handleFilterChange}
                    />
                </FilterWrapper>
            </Wrapper>
            <Footer>
                <ClearButton filters={filters} setFilters={setFilters} />
                <Button
                    sizeVariant="medium"
                    onClick={handleSubmitNewFilters}
                    disabled={
                        isEqual(searchQuery.filters, initialSearchQuery.filters) &&
                        isEqual(searchQuery.channel, initialSearchQuery.channel)
                    }
                >
                    Search
                </Button>
            </Footer>
        </FiltersWrapper>
    )
}

export default Filters;
