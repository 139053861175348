import { ApolloLink } from '@apollo/client';
import getState, { X_TRANSACTION_ID_HEADER } from '../../../server/util/transactionId';

const getTransactionIdHeader = () => {
    const state = getState();
    if(state) {
        return { [X_TRANSACTION_ID_HEADER]: state.transactionId };
    }
    return {};
};

const getUserAgentHeader = () => ({ 'User-Agent': 'app-identifier-here' });

const setOutboundRequestHeaders = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            ...getTransactionIdHeader(),
            ...getUserAgentHeader()
        }
    });
    return forward(operation);
});

export default setOutboundRequestHeaders;
