import { Location, GpsLocation } from "../types";

const createQueryGpsLocation = (gpsLocation: Array<number>): GpsLocation => {
    return {
        gpsLocationSearch: gpsLocation
    }
}

const isValidGpsLength = (arr: Array<number>) => arr.length === 2;

const isGpsLocation = (location: Location): location is GpsLocation => (location as GpsLocation).gpsLocationSearch !== undefined;

export {
    createQueryGpsLocation,
    isGpsLocation,
    isValidGpsLength
}