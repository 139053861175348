import restaurantDetailsQuery from './restaurantDetailsQuery.graphql';
import checkErrors from '../checkErrors';
import { createError } from '../createError';
import createVariables from './createVariables';

const hasResults = data => data != null;

const fetchData = ({ client, location }) => {
    return resultsFetchData({ resultsQuery: restaurantDetailsQuery })({ client, location });
};

const slugRegex = /^(?:.*[-/])?([a-zA-Z0-9-_]+)$/;

const extractId = (pathname) => {
    const matched = slugRegex.exec(pathname);
    return matched ? matched[1] : null;
}

const resultsFetchData = ({ resultsQuery }) => ({ client, location, fetchPolicy = 'cache-first' }) => {
    const { pathname, search, query } = location;
    const restaurantId = extractId(pathname);

    const searchParams = {
        restaurantId
    }

    return client
        .query({
            query: resultsQuery,
            variables: createVariables(searchParams),
            fetchPolicy
        })
        .then(checkErrors(hasResults))
        .catch((error) => {
            console.log(error.networkError.result)
            createError({ dataBeingFetched: `restaurants` })
        });
};

export default fetchData;
