import styled from "styled-components"
import { paletteHelper, spacingHelper, typographyHelper } from "../../utils"
import { NittyTheme } from "../nitty/types"
import { P } from "../text/shorthand-text"
import { TextInputBaseSize, TextInputBaseSquaredCorners } from "./text-input-base.types"
import Button from "../button"

type Helper = {
    theme: NittyTheme
    sizeVariant: TextInputBaseSize
}

const heightHelper = ({ theme, sizeVariant }: Helper) => {
    const { spacing } = theme;
    const height = {
        large: spacing.extraLarge,
        medium: spacing.large,
        small: spacing.large
    }
    return height[sizeVariant] || spacing.extraLarge;
}

type BorderHelper = {
    errored: boolean
    focused: boolean
    disabled: boolean
    theme: NittyTheme
}

const borderHelper = ({ errored, focused, disabled, theme }: BorderHelper) => {
    const { border, palette } = theme;
    if(errored) {
        return `${border.width.thick} solid ${palette.borderErrored}`
    }else if(focused && !disabled) {
        return `${border.width.thick} solid ${palette.borderFocused}`
    }else if(disabled) {
        return `${border.width.thin} solid ${palette.borderDisabled}`
    }else{
        return `${border.width.thin} solid ${palette.borderPrimary}`
    }
}

const marginBottomHelper = ({ sizeVariant, theme }: Helper) => {
    const { spacing } = theme;

    const margins = {
        large: spacing.extraSmall,
        medium: spacing.extraSmall,
        small: spacing.twoExtraSmall
    }
    return margins[sizeVariant];
}

const paddingHelper = ({ sizeVariant, theme }: Helper) => {
    const { spacing } = theme;
    const paddings = {
        large: `${spacing.extraSmall} ${spacing.medium} 0`,
        medium: `${spacing.extraSmall} ${spacing.medium} 0`,
        small: `${spacing.twoExtraSmall} ${spacing.small} 0`
    }
    return paddings[sizeVariant]
}

type InputProps = {
    theme: NittyTheme
    sizeVariant: TextInputBaseSize,
    inputMinWidth: string
}

const Input = styled.input<InputProps>`
    ${({ sizeVariant }) =>
        typographyHelper(sizeVariant === "small" ? "body02" : "body01")}

    border: none;
    color: ${({ disabled }) =>
        disabled ? paletteHelper("textDisabled") : paletteHelper("textPrimary")};
    flex-grow: 1;

    height: ${heightHelper};
    ${({ inputMinWidth }) => inputMinWidth === "0" && "width: 100%;"};
    outline: none;
    box-shadow: none;
    padding: 0;
    margin-bottom: ${marginBottomHelper};
    background-color: ${({ disabled, theme}) =>
        disabled
            ? theme.palette.backgroundDisabled
            : theme.palette.backgroundPrimaryBase};
    ::placeholder {
        color: ${({ disabled, theme }) =>
            disabled ? theme.palette.textDisabled : theme.palette.textSecondary };
    }
    cursor: ${({ disabled }) => (disabled ? `not-allowed` : `inherit`)};
    ::-ms-clear,
    ::-ms-reveal {
        display: none;
    }
    ::-webkit-contacts-auto-fill-button {
        background-color: ${paletteHelper("textPrimary")};
    }
`

const borderRadius = (
    theme: NittyTheme,
    squaredCornersPosition: TextInputBaseSquaredCorners
) => {
    const radius = theme.border.radius.extraLarge;
    if (squaredCornersPosition === "none") return radius;
    return squaredCornersPosition === "bottom" ? `${radius} ${radius} 0 0` : `0 0 ${radius} ${radius}`
}

type InputBorderProps = {
    errored: boolean
    focused: boolean
    disabled: boolean
    squaredCornersPosition: TextInputBaseSquaredCorners
}

const InputBorder = styled.div<InputBorderProps>`
    background-color: ${({ disabled, theme }) =>
        disabled
            ? theme.palette.backgroundDisabled
            : theme.palette.backgroundPrimaryBase};
    cursor: ${({ disabled }) => (disabled ? `not-allowed`: `inherit`)};
    position: relative;
    border-radius: ${( { theme, squaredCornersPosition }) =>
        borderRadius(theme, squaredCornersPosition)};

        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            pointer-events: none;

            border-radius: ${({ theme, squaredCornersPosition }) =>
                borderRadius(theme, squaredCornersPosition)};
            border: ${borderHelper}
        }
`

type ScrollableArea = {
    maxHeight: string
    sizeVariant: TextInputBaseSize
}

const ScrollableArea = styled.div<ScrollableArea>`
    max-height: ${( { maxHeight }) => maxHeight};
    overflow: auto;

    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: ${paddingHelper};
`

const ValidationError = styled(P).attrs({ variant: "body02" })`
    margin: ${spacingHelper("twoExtraSmall 0")};
    color: ${paletteHelper("textWarning")};
`

const HelpText = styled(P).attrs({ variant: "body02"})`
    margin: ${spacingHelper("twoExtraSmall 0")};
    ${({ disabled, theme }) => disabled && `color: ${theme.palette.textDisabled}`}
`

type ButtonHelper = {
    $sizeVariant: TextInputBaseSize
    theme: NittyTheme
}

const buttonMarginHelper = ({ $sizeVariant, theme}: ButtonHelper) => {
    const {spacing} = theme;
    const margin = {
        large: `0 -${spacing.extraSmall} 0 0`,
        medium: `-${spacing.twoExtraSmall} -${spacing.small} 0 0`,
        small: `-${spacing.twoExtraSmall} -${spacing.small} 0 0`
    }
    return margin[$sizeVariant]
}

const ClearButton = styled(Button).attrs({ sizeVariant: "small" })`
    margin: ${buttonMarginHelper};
`

export {
    Input,
    InputBorder,
    ScrollableArea,
    ValidationError,
    HelpText,
    ClearButton
}
