import { PAGE_DATA_LOADED } from '../actions/Actions';

const initialState = {};

export default (state = initialState, action) => {
    switch(action.type) {
        case PAGE_DATA_LOADED: {
            const { data, error } = action;
            return { ...state, data, error };
        }
        default:
            return state;
    }
};
