import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Pill from "../../../pill";
import { ButtonProps } from "../../../../@nitty";

type StyledPillProps = {
    minDeviceWidth?: number;
}

const StyledPill = styled(Pill)<StyledPillProps & ButtonProps>`
    padding: 0.75rem;
    height: 3rem;

    svg {
        width: 1.375rem;
        height: 1.375rem;
    }

    ${(props) =>
        props.minDeviceWidth &&
        `
            display: none;
            @media only screen and (min-width: ${props.minDeviceWidth}px) {
                display: inherit;
            }
        `}
`;

type FilterPillProps = {
    onClick: () => void;
    selected: boolean;
    minDeviceWidth?: number;
};

const FilterPill: FunctionComponent<ButtonProps & FilterPillProps> = ({
    onClick,
    selected,
    children,
    minDeviceWidth,
    ...props
}) => {
    return (
        <StyledPill
            selected={selected}
            minDeviceWidth={minDeviceWidth}
            onClick={onClick}
            {...props}
        >
            {children}
        </StyledPill>
    )
}

export default FilterPill;
