import React from "react";
import styled, { CSSObject } from "styled-components";
import { replaceToken } from "../../private/helpers";
import forwardRef from "../../private/forwardRef";
import { StackProps } from "./stack.types";

const defaultProps = {
    inset: "0",
    gap: "1.5rem",
    justifyContent: "flex-start",
    alignItems: "stretch"
}

type StackContainerProps = {
    justifyContent: CSSObject["justifyContent"]
    alignItems: CSSObject["alignItems"]
    inset: string
    gap: string
}

const StackContainer = styled.div<StackContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.justifyContent};
    align-items: ${props => props.alignItems};
    padding: ${({ theme, inset }) => replaceToken(theme.spacing, inset)};
    & > *:not(:last-child) {
        margin-bottom: ${({ theme, gap }) => replaceToken(theme.spacing, gap)};
    }
`

const Stack = forwardRef<StackProps, "div">((props, externalRef) => {
    const { inset, gap, justifyContent, alignItems, children, ...otherProps } = {
        ...defaultProps,
        ...props
    }

    return (
        <StackContainer
            ref={externalRef}
            inset={inset}
            gap={gap}
            justifyContent={justifyContent}
            alignItems={alignItems}
            {...otherProps}
        >
            {children}
        </StackContainer>
    )
})

Stack.displayName = "Stack"

export default Stack;