import { useEffect, useState } from "react";

export const queries = [
    {
        name: "isSmallScreen",
        condition: "(max-width: 600px)"
    }
];

export const serverQueryResults = {
    isSmallScreen: false
};

const initialiseQueryResults = () => typeof window === 'undefined' ? serverQueryResults : queries.reduce((state, { name, condition}) => ({
    ...state,
    [name]: window.matchMedia(condition).matches}), {});

const useMedia = () => {
    const [queryResults, setQueryResults] = useState(initialiseQueryResults);

    useEffect(() => {
        const mediaQueryList = queries.map(({ name, condition }) => ({
            name,
            mediaQuery: window.matchMedia(condition)
        }));

        const handleMediaUpdate = () => {
            const updatedQueryResults = mediaQueryList.reduce(( newState, { name, mediaQuery }) => ({
                ...newState,
                [name]: mediaQuery.matches
            }), {});

            setQueryResults(updatedQueryResults);
        };

        mediaQueryList.forEach(({ mediaQuery }) => {
            if(mediaQuery.addEventListener) {
                mediaQuery.addEventListener("change", handleMediaUpdate);
            }else{
                mediaQuery.addListener(handleMediaUpdate);
            }
        });

        return () => {
            mediaQueryList.forEach(({ mediaQuery }) => {
                if(mediaQuery.removeEventListener) {
                    mediaQuery.removeEventListener("change", handleMediaUpdate);
                }else{
                    mediaQuery.removeListener(handleMediaUpdate);
                }
            });
        }

    }, []);

    return queryResults;
};

export default useMedia;
