import React, { FunctionComponent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Channel, QueryFilters, SearchQuery, updateSearchQuery } from "../../@nearbites.com/query";
import { generateSearchResultUrl, parseSearchUrl } from "../../@nearbites.com/query"
import { Inline, H2 } from "../../@nitty";
import { BrowserHistoryContext } from "../../client/history";
import AppContext from '../../util/AppContext';
import GpsPill from "./components/gps-pill";
import SearchButton from './components/search-button';
import FiltersButton from './components/filters-button';
import Filters, { isEqual } from './components/filters';
import Search from './components/search';
import Modal from '../../components/modal';
import useSearch from "../../util/search/useSearch";
import { SearchQueryActionType } from "../../util/search/types";
import Suggestions, { SuggestionButton } from "./components/suggestions";

export type SearchBarProps = {
    channels: Array<Channel>;
    searchQuery: SearchQuery;
    onSubmitSearch: (searchQuery: SearchQuery) => void;
    modalMountID?: string | HTMLElement
}

const SearchWrapper = styled(Inline).attrs(() => ({
    grow: false,
    gap: "small"
}))`
    max-width: 45rem;
    width: 100%;
    padding: 1rem 0;
    position: relative;
    justify-content: flex-end;
`;

const SuggestionsWrapper = styled.div`
    overflow: hidden;
    flex: 1;
`;

const mapStringToOption = (text, type) => ({display: {text}, type});

const mapKeywordsToOptions = (keywords) => {
    if (keywords.length > 0) {
        return keywords.map(keyword => mapStringToOption(keyword, 'keyword'))
    }
    return []
}

const mapMealTypesToOptions = (mealTypes) => {
    if (mealTypes.length > 0) {
        return mealTypes.map(mealType => mapStringToOption(mealType, 'tag'))
    }
    return []
}

const mapLocationsToOptions = (locations) => {
    if(locations) {
        if("searchLocations" in locations) {
            return [
                ...locations.searchLocations.map(location => mapStringToOption(location, 'location'))
            ]
        }else if("gpsLocationSearch" in locations) {
            return [
                mapStringToOption('GPS location', 'location')
            ]
        }
    }
    return []
}

const getSelectedOptions = (searchQuery: SearchQuery) => {
    const { keywords, mealTypes, location } = searchQuery.filters;

    return [
        ...mapKeywordsToOptions(keywords),
        ...mapMealTypesToOptions(mealTypes),
        ...mapLocationsToOptions(location),
    ]
}

const SearchBar: FunctionComponent<SearchBarProps> = ({
    channels,
    modalMountID = "#wrapper"
}) => {
    const { mediaQueryList: { isSmallScreen } } = useContext(AppContext);
    const { searchQuery: initialSearchQuery, updateSearchQuery: updateGlobalSearchQuery } = useSearch();

    const [showFiltersModal, setShowFiltersModal] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);

    const [searchQuery, setSearchQuery] = useState<SearchQuery>(initialSearchQuery);
    const { channel } = searchQuery;

    const selectedOptions: string[] = getSelectedOptions(searchQuery);

    const showFilters = () => setShowFiltersModal(true);
    const hideFilters = () => setShowFiltersModal(false);

    const showSearch = () => setShowSearchModal(true);
    const hideSearch = () => setShowSearchModal(false);

    useEffect(() => {
        if(!isEqual(initialSearchQuery.filters, searchQuery.filters)) {
            setSearchQuery(prevState => ({
                ...prevState,
                filters: { ...initialSearchQuery.filters }
            }))
        }
    }, [initialSearchQuery.filters])

    useEffect(() => {
        setShowFiltersModal(false);
    }, [searchQuery])

    useEffect(() => {
        if(!isSmallScreen) {
            setShowSearchModal(false);
        }
    }, [isSmallScreen]);

    return (
        <BrowserHistoryContext.Consumer>
            {history => {
                const onSubmitSearch = (searchQuery: SearchQuery) => {
                    const url = generateSearchResultUrl(searchQuery);
                    setShowFiltersModal(false);
                    updateGlobalSearchQuery({ type: SearchQueryActionType.UpdateSearchQuery, searchQuery: parseSearchUrl(url) })
                    history && history.push(url);
                }

                const setFilters = (filters: Partial<QueryFilters>) => {
                    const newSearchQuery = {
                        ...searchQuery,
                        filters: {
                            ...searchQuery.filters,
                            ...filters
                        }
                    }
                    setSearchQuery(newSearchQuery);
                }

                const handleFilterChange = <K extends keyof QueryFilters>(filterName: K) => (newValue: QueryFilters[K]) => {
                    setFilters({ [filterName]: newValue });
                }

                const handleSuggestionChange = (suggestions) => {
                    const newFilters = {};

                    const keywords = suggestions
                                        .filter(suggestion => suggestion.type === "keyword")
                                        .map(keyword => keyword.display.text);

                    newFilters.keywords = keywords;

                    const suggestionMealTypes = ['tag'];
                    const mealTypes = suggestions
                                        .filter(suggestion => suggestionMealTypes.includes(suggestion.type))
                                        .map(mealType => mealType.display.text);

                    newFilters.mealTypes = mealTypes;

                    const searchLocations = suggestions
                                        .filter(suggestion => suggestion.type === "location" && suggestion.display.text.toLowerCase() != "gps location")
                                        .map(location => location.display.text);

                    if(searchLocations.length) {
                        // NOTE: If you want the user to be able to search multiple locations just set searchLocations
                        // to the full array and not just the last item
                        newFilters.location = { searchLocations: [searchLocations[searchLocations.length - 1]] };
                    }else{
                        newFilters.location = { searchLocations: [] };
                    }

                    const currentLocation = suggestions
                                        .filter(suggestion => suggestion.type === "location" && suggestion.display.text.toLowerCase() === "gps location")
                                        .map(location => location.display.text);

                    if(currentLocation.length && searchLocations.length === 0) {
                        newFilters.location = { ...searchQuery.filters.location };
                    }

                    setFilters(newFilters);
                }

                const handleSubmitNewFilters = () => {
                   if (!isEqual(searchQuery.filters, initialSearchQuery.filters) ||
                        !isEqual(searchQuery.channel, initialSearchQuery.channel)) {
                        const newSearchQuery = updateSearchQuery({
                            ...searchQuery.filters,
                            channel: searchQuery.channel,
                            page: 1
                        })(searchQuery);
                        onSubmitSearch(newSearchQuery);
                    }
                }

                return (
                    <>
                        <SearchWrapper>
                            {!isSmallScreen ? (
                                <>
                                    <SuggestionsWrapper>
                                        <Suggestions
                                            onChange={handleSuggestionChange}
                                            selectedOptions={selectedOptions}
                                            channel={channel}
                                        />
                                    </SuggestionsWrapper>
                                    {/* <ChannelFilter /> */}
                                    {/* <Text>Meals</Text> */}
                                    {/* <Text>Anywhere</Text> */}
                                    <GpsPill
                                        onClick={onSubmitSearch}
                                        searchQuery={searchQuery}
                                    />
                                    {/* <SearchTypeFilter /> */}
                                    {/* <Text>Maps/List</Text> */}
                                    {/*<FiltersButton
                                        onClick={showFilters}
                                        searchQuery={searchQuery}
                                    />*/}
                                    <SearchButton
                                        onClick={handleSubmitNewFilters}
                                    />
                                </>
                            ) : (
                                <>
                                    <SuggestionsWrapper>
                                        <SuggestionButton
                                            onClick={showSearch}
                                            selectedOptions={selectedOptions}
                                        />
                                    </SuggestionsWrapper>
                                    <GpsPill
                                        onClick={onSubmitSearch}
                                        searchQuery={searchQuery}
                                    />
                                </>
                            )}
                        </SearchWrapper>
                        {/*<Modal
                            openModal={showFiltersModal}
                            handleCloseModal={hideFilters}
                            fullScreenMediaQuery="(max-width: 600px)"
                            modalMountID={modalMountID}
                            header={<H2>Filters</H2>}
                            headerAlignment="center"
                        >
                            <Filters
                                channels={channels}
                                searchQuery={searchQuery}
                                onSubmitSearch={onSubmitSearch}
                            />
                        </Modal>*/}
                        <Modal
                            openModal={showSearchModal}
                            handleCloseModal={hideSearch}
                            fullScreenMediaQuery="(max-width: 600px)"
                            hideDefaultCloseButton
                            modalMountID={modalMountID}
                        >
                            <Search
                                closeModal={hideSearch}
                                searchQuery={searchQuery}
                                onSuggestionChange={handleSuggestionChange}
                                onSubmitSearch={onSubmitSearch}
                                selectedOptions={selectedOptions}
                            />
                        </Modal>
                    </>
                )
            }}
        </BrowserHistoryContext.Consumer>
    )
}

export default SearchBar;
