import { useCallback, useEffect, useMemo, useState } from 'react';
import defaultIntersectionObserver from '../../client/IntersectionObserverFacade';

export default function useLazy(intersectionObserver = defaultIntersectionObserver) {
    const isIntersectionObserverSupported = intersectionObserver.isSupported;
    const supported = useMemo(() => {
        if(typeof window === 'undefined') {
            return true;
        }
        return isIntersectionObserverSupported();
    }, [isIntersectionObserverSupported]);

    const [observer, setObserver] = useState(null);
    const [ready, setReady] = useState(false);
    const [ref, setRef] = useState(null);

    const handleRef = useCallback((el) => {
        if(ref !== el) {
            setRef(el);
        }
    }, [ref, setRef]);

    const handler = useCallback(() => {
        setReady(true);
        if(observer) {
            observer.unobserve();
        }
    }, [setReady, observer]);

    const forceReadyForUnsupportedBrowsers = useCallback(() => {
        setReady(true);
        return () => {}
    }, [setReady]);

    const observeElementWhenAvailable = useCallback(() => {
        if(ref && !observer) {
            setObserver(intersectionObserver.observe(handler, ref));
        }

        return () => {
            if(observer) {
                observer.unobserve();
            }
        }
    }, [handler, intersectionObserver, observer, ref, setObserver]);

    useEffect(() => {
        const effect = supported ? observeElementWhenAvailable : forceReadyForUnsupportedBrowsers;
        return effect();
    }, [forceReadyForUnsupportedBrowsers, observeElementWhenAvailable, supported]);

    const reset = useCallback(() => {
        if(!supported) return;
        if(observer) {
            observer.unobserve();
            setObserver(null);
        }
        if(ready) {
            setReady(false);
        }
    }, [observer, ready, setReady, setObserver, supported]);

    return [ready, handleRef, reset];
}
