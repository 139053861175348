import { useCallback, useEffect, useReducer } from 'react';
import createRouterLocation from './createLocationFromHistoryLocation';
import resolveCurrentScreen, { RedirectError } from './resolveCurrentScreen';

function reducer(state, action) {
    switch(action.type) {
        case 'CHANGE_SCREEN': {
            const { view, location } = action.payload;
            return {
                ...state,
                error: null,
                view,
                location
            };
        }
        case 'ERROR': {
            const { error, location } = action.payload;
            return {
                ...state,
                error,
                location
            };
        }
        default: {
            return state;
        }
    }
}

function noop() {}

export default function useCurrentScreen(
    router,
    history,
    initialView,
    store,
    onChange = noop,
    onTransitionStart,
    onTransitionEnd
) {
    const initialLocation = createRouterLocation(history.location);
    const [state, dispatch] = useReducer(reducer, {
        error: null,
        loading: false,
        location: initialLocation,
        view: initialView
    });
    const { view, error, location } = state;
    const { listen, replace } = history;

    const resolveRoute = useCallback(
        ({ location, action }) => {
            const routerLocation = createRouterLocation(location);
            onTransitionStart();
            resolveCurrentScreen(router, location, store)
                .then(view => {
                    onChange(action);
                    dispatch({ type: 'CHANGE_SCREEN', payload: { view, location: routerLocation } });
                    return view;
                })
                .catch(error => {
                    if(error instanceof RedirectError) {
                        replace(error.url);
                    }else{
                        dispatch({ type: 'ERROR', payload: { error, location: routerLocation } });
                    }
                })
                .finally(onTransitionEnd);
        },
        [onChange, replace, router, store, onTransitionStart, onTransitionEnd]
    );

    useEffect(() => {
        return listen(resolveRoute);
    }, [listen, resolveRoute]);

    return [error, view, location];
}
