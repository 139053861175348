import createLocationFromHistoryLocation from './createLocationFromHistoryLocation';
import { Error as ErrorScreen } from '../screens/error';
import { ERROR_SCREEN } from './types';
import appLogger from '../util/logging/appLogger';

export class RedirectError extends Error {
    url;
    statusCode;
    constructor(url, statusCode, number = 301, ...args) {
        super(...args);
        this.url = url;
        this.statusCode = statusCode;
    }
}

class NotFoundError extends Error {}

function responseAsPromise(response) {
    return new Promise((resolve, reject) => {
        try {
            response.fold({
                ifSuccess: resolve,
                ifServerError: appError => {
                    appError.error.fold(reject, reject)
                },
                ifNotFound: () => {
                    reject(new NotFoundError());
                },
                ifPermanentRedirect: url => {
                    reject(new RedirectError(url));
                },
                ifNetworkError: appError => {
                    appError.error.fold(reject, reject)
                }
            })
        } catch(error) {
            reject(error);
        }
    });
}

export default function resolveCurrentScreen(router, location, store) {
    const routerLocation = createLocationFromHistoryLocation(location);
    const routerContext = { pathname: routerLocation.pathname, location: routerLocation };

    /* eslint-disable promise/no-callback-in-promise */
    /* eslint-disable promise/no-nesting */
    return router.resolve(routerContext).then(action => {
        const { fetchData, postFetchData, screenName, Screen } = action;
        return fetchData({})
            .then(response => {
                postFetchData && postFetchData({ dispatch: store.dispatch });
                return response;
            })
            .then(responseAsPromise)
            .then(
                pageData => ({ pageData, Screen, screenName }),
                error => {
                    if(error instanceof RedirectError) {
                        throw error;
                    }else{
                        const notFound = error instanceof NotFoundError;
                        if(!notFound) {
                            appLogger.error('Error resolving current screen')(error);
                        }
                        const statusCode = notFound ? 404 : 500;
                        return {
                            pageData: {
                                statusCode,
                                error: statusCode
                            },
                            Screen: ErrorScreen,
                            screenName: ERROR_SCREEN
                        };
                    }
                }
            );
    });
    /* eslint-enable promise/no-callback-in-promise */
    /* eslint-enable promise/no-nesting */
}
