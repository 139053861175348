import styled from "styled-components"
import { TabsVariant } from "./tabs.types"
import { paletteHelper, spacingHelper, typographyHelper } from "../../utils"
import { replaceToken } from "../../private/helpers"

const HorizontalList = styled.ul`
    height: 3.8125rem;
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    display: flex;
    scroll-behavior: smooth;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`

type FloatyLineProps = {
    underlineColor: string
    active: boolean
}

const FloatyLine = styled.span<FloatyLineProps>`
    height: 0.125rem;
    width: 100%;
    bottom: -1px;
    left: 0;
    background-color: ${({ theme, underlineColor }) => replaceToken(theme.palette, underlineColor)};
    position: absolute;
    display: ${({ active }) => (active ? "block" : "none")};
`;

type ChevronButtonProps = {
    backgroundColor: string
    hideTabBarLine: boolean
}

const ChevronButton = styled.button<ChevronButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: ${({ theme }) => `${theme.border.width.thin} solid rgba(0, 0, 0, 0)`};
    background-color: ${({ theme, backgroundColor }) => replaceToken(theme.palette, backgroundColor)};
    color: ${paletteHelper("textSecondary")};
    margin: 0;
    font: inherit;
    width: ${spacingHelper("twoExtraLarge")};
    border-bottom: ${({ theme, hideTabBarLine }) => !hideTabBarLine && `${theme.border.width.thin} solid ${theme.palette.borderSecondary}`};
    @media (hover: hover) {
        &:hover {
            text-decoration: underline;
            color: ${paletteHelper("backgroundSelected")}
        }
    }
    &:focus {
        outline: none;
    }
`

type ChevronPosition = "left" | "right"

type FadeySpacerProps = {
    position: ChevronPosition
    backgroundColor: string
}

const FadeySpacer = styled.div<FadeySpacerProps>`
    display: inline-block;
    background: linear-gradient(${({ position }) => (position === "left" ? 270 : 90)}deg, rgba(255,255,255, 0), ${({ theme, backgroundColor }) => replaceToken(theme.palette, backgroundColor)});
    height: calc(100% - 0.125rem);
    width: ${spacingHelper("medium")};
`

type ChevronWrapperProps = {
    visible: boolean
    position: ChevronPosition
}

const ChevronWrapper = styled.div<ChevronWrapperProps>`
    position: absolute;
    top: 0.0625rem;
    height: 100%;
    display: ${({ visible }) => (visible ? "flex" : "none")};
    ${({ position }) => (position === "left" ? "left: 0" : "right: 0")};
    z-index: 1;
`

type HorizontalListItemProps = {
    variant: TabsVariant
    width: string
}

const HorizontalListItem = styled.li<HorizontalListItemProps>`
    ${({ variant, width }) => variant === "full-width" && `width: ${width};`}
    min-width: fit-content;
    height: ${({ theme }) => theme.spacing.threeExtraLarge };
    display: inline-block;
    position: relative;
`

type TabButtonProps = {
    selected: boolean
}

const TabButton = styled.button<TabButtonProps>`
    ${typographyHelper("subtitle01")}
    border: none;
    background: transparent;
    padding: ${({ theme }) => `${theme.spacing.extraSmall} ${theme.spacing.small}`}
    margin: 0;
    color: ${({ selected, theme }) => selected ? theme.palette.backgroundSelected : theme.palette.textSecondary};
    width: 100%;
    cursor: pointer;
    border: none;

    @media (hover: hover) {
        &:hover {
            text-decoration: underline;
            color: ${paletteHelper("backgroundSelected")};
        }
    }

    &:focus {
        outline: none;
        background-color: ${paletteHelper("backgroundFocusedPrimary")};
    }

    &:focus-visible {
        outline: none;
        background-color: ${paletteHelper("backgroundFocusedPrimary")};
    }

    &:focus:not(:focus-visible) {
        background: none;
    }

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden

    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: ${({ theme }) => theme.spacing.threeExtraLarge }
`

export {
    HorizontalList,
    HorizontalListItem,
    TabButton,
    FloatyLine,
    ChevronButton,
    FadeySpacer,
    ChevronWrapper
}