import React from "react"
import { TabsVariant } from "./tabs.types"

type TabsContextValues = {
    selectedTab: number
    setSelectedTab: (selectedTab: number) => void
    underlineColor: string
    variant: TabsVariant
    hideTabBarLine: boolean
}

const tabsContextDefault: TabsContextValues = {
    selectedTab: 0,
    setSelectedTab: () => {},
    underlineColor: "",
    variant: "align-left",
    hideTabBarLine: false
}

export default React.createContext<TabsContextValues>(tabsContextDefault)