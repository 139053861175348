import React, { ReactNode } from "react"
import forwardRef from "../../private/forwardRef";
import { replaceToken } from "../../private/helpers";
import styled from "styled-components";
import { NittyTheme } from "../nitty/types";
import { SkeletonProps } from "./skeleton.types";
import { Container } from "./styles";

const defaultProps = {
    borderRadius: "small",
}

interface SkeletonContainerProps {
    theme: NittyTheme
    borderRadius: string
    width: string
    height: string
    children: ReactNode
}

const dimensionHelper = (
    theme: NittyTheme,
    length: string,
    children: ReactNode
) => {
    if (length) return replaceToken(theme.spacing, length)
    return children ? `fit-content` : `100%`
}

const SkeletonContainer = styled(Container)<SkeletonContainerProps>`
    height: ${({ borderRadius, width, height, children, theme }) =>
        borderRadius === "circular" 
            ? width
            : dimensionHelper(theme, height, children)};
    width: ${({ width, children, theme }) => 
        dimensionHelper(theme, width, children)};

    & > div {
        visibility: hidden;
    }
`

const Skeleton = forwardRef<SkeletonProps, "div">((props, externalRef) => {
    const { borderRadius, width, height, children, ...otherProps } = {
        ...defaultProps,
        ...props
    }
    return (
        <SkeletonContainer
            ref={externalRef}
            borderRadius={borderRadius}
            width={width}
            height={height}
            {...otherProps}
        >
            {children && <div>{children}</div>}
        </SkeletonContainer>
    )
});

Skeleton.displayName = "Skeleton"

export default Skeleton;