const createAppLogger = errorLogger => {
    return {
        error: (message, customAttributes) => (error)  => {
            /*eslint-disable no-console */
            if(error && error.stack) {
                console.error(message, error.stack);
            }else{
                console.log(error);
            }
            /*eslint-enable no-console */

            if(errorLogger) {
                errorLogger(message, error, customAttributes);
            }
        },
        /*eslint-disable no-console */
        log: (...args) => console.log(...args),
        warn: (...args) => console.warn(...args)
        /*eslint-enable no-console */
    };
};

const reportToNewRelic = (message, error, customAttributes) => {
    const errorOrMessage = error || message;

    if(typeof NREUM !== 'undefined') {
        try {
            throw errorOrMessage;
        } catch(error) {
            NREUM.noticeError(error, customAttributes);
        }
    }
};

export const clientLogger = createAppLogger(reportToNewRelic);
