import 'isomorphic-fetch';
import { createHttpLink } from '@apollo/client';
import createPersistedQueryLink from './createPersistedQueryLinkWithMeasurements';
import { API_HOST_CLIENT, API_HOST_SERVER } from '../../../../../config/environment';
import setOutboundRequestHeaders from './setOutboundRequestHeaders';
import { Cookies } from '../../cookies';

export default (isServer, { user_id_here }) => {
    if(isServer) {
        const cookies = {};
        if (user_id_here) cookies.user_id_here = user_id_here;
        const cookiesString = Cookies.stringify(cookies);
        const headers = cookiesString ? { Cookie: cookiesString } : {};
        const httpLink = createHttpLink({
            uri: `${API_HOST_SERVER}/graphql`,
            headers
        });
        return httpLink;
    }
    const httpLink = createHttpLink({ uri: `${API_HOST_CLIENT}/graphql`, credentials: 'include' });
    return httpLink;
};
