import React, {useState} from "react"
import { useUpdateEffect, usePrevious } from "../../private/helpers"
import TabsContext from "./tabs-context"
import { TabsProps, TabsVariant } from "./tabs.types"

const defaultProps = {
    defaultSelectedTab: 0,
    variant: "align-left" as TabsVariant,
    hideTabBarLine: false,
    underlineColor: "backgroundBrandStatic"
}

const Tabs: React.FC<TabsProps> = props => {
    const {
        children,
        variant,
        underlineColor,
        hideTabBarLine,
        onSelectedTabChange,
        selectedTab,
        defaultSelectedTab
    } = { ...defaultProps, ...props }

    const [internalSelectedTab, setInternalSelectedTab] = useState<number>(
        selectedTab || defaultSelectedTab
    )

    const previousInternalSelectedTab = usePrevious(internalSelectedTab)
    const previousSelectedTab = usePrevious(selectedTab)

    useUpdateEffect(() => {
        if(!onSelectedTabChange) return

        if(internalSelectedTab !== previousInternalSelectedTab) 
            onSelectedTabChange({ index: internalSelectedTab })
        else if (selectedTab !== previousSelectedTab)
            onSelectedTabChange({ index: selectedTab })
    }, [
        internalSelectedTab,
        previousInternalSelectedTab,
        selectedTab,
        previousSelectedTab,
        onSelectedTabChange
    ])

    return (
        <TabsContext.Provider
            value={{
                selectedTab: selectedTab || internalSelectedTab,
                setSelectedTab: setInternalSelectedTab,
                underlineColor,
                variant,
                hideTabBarLine
            }}
        >
            {children}
        </TabsContext.Provider>
    )
}

export default Tabs;