import Cls from 'continuation-local-storage';

let namespace;
export const getNamespace = () => {
    if (!namespace) {
        namespace = Cls.createNamespace('com.nearbites.transaction-id');
    }
    return namespace;
};

export const X_TRANSACTION_ID_HEADER = 'X-Transaction-Id';
export const TRANSACTION_ID_STATE_KEY = 'transactionIdState';

const getState = () => {
    return getNamespace().get(TRANSACTION_ID_STATE_KEY);
};

export default getState;
