import { QueryFilters, SearchQuery } from "../../@nearbites.com/query";
import { SearchQueryAction, SearchQueryActionType } from "./types";

const setFilter = <K extends keyof QueryFilters>(
    searchQuery: SearchQuery,
    filterName: K,
    value: QueryFilters[K]
): SearchQuery => {
    const newSearchQuery = {
        ...searchQuery,
        filters: {
            ...searchQuery.filters,
            [filterName]: value
        }
    }
    return newSearchQuery;
}

const reducer = (
    searchQuery: SearchQuery,
    action: SearchQueryAction
): SearchQuery => {
    switch(action.type) {
        case SearchQueryActionType.UpdateChannel:
            const channels: Array<"meals" | "restaurants"> = [
                "meals",
                "restaurants"
            ];
            const newChannel = channels[action.channel];
            return { ...searchQuery, channel: newChannel};
        case SearchQueryActionType.UpdateLocation:
            return setFilter(searchQuery, "location", {
                searchLocations: action.locations.map((location) =>
                    typeof location === "string" ? location : location.display.text)
            });
        case SearchQueryActionType.UpdateKeywords:
            return setFilter(searchQuery, "keywords", action.keywords);
        case SearchQueryActionType.UpdateSearchQuery:
            return { ...action.searchQuery };
        default:
            return searchQuery;
    }
}

export default reducer;
