import React from "react";
import forwardRef from "../../../private/forwardRef";
import { IconProps } from '../icon.types';
import { nanoid } from "nanoid/non-secure"

const CrossLg = forwardRef<IconProps, "svg">(({ className, ...props}, ref) => {
    const titleId = props?.id ? `${props.id}-title` : nanoid(5);
    return (
        <svg
            className={["Nitty__Icon--lg", className].filter(Boolean).join(" ")}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={ref}
            aria-labelledby={props?.title ? titleId : undefined}
            {...props}
        >
            {props?.title && <title id={titleId}>{props.title}</title>}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="currentColor"
                d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
            />
        </svg>
    )
})

CrossLg.displayName = "CrossLg"

export default CrossLg;
