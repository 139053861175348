import homeQuery from '../search-results/searchResultsQuery.graphql';
import checkErrors from '../checkErrors';
import { createError } from '../createError';
import createVariables from '../search-results/createVariables';
import { parseSearchUrl } from '../../@nearbites.com/query';

const hasResults = data => data != null;

const fetchData = ({ client, location }) => {
    return resultsFetchData({ resultsQuery: homeQuery })({ client, location });
};

const resultsFetchData = ({ resultsQuery }) => ({ client, location, fetchPolicy = 'cache-first' }) => {
    const searchQuery = parseSearchUrl('/meals/');

    const queryParams = {
        searchQuery
    }

    return client
        .query({
            query: resultsQuery,
            variables: createVariables(queryParams),
            fetchPolicy
        })
        .then(checkErrors(hasResults))
        .catch(createError({ dataBeingFetched: `home` }));
};

export default fetchData;
