import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/object';
import 'core-js/es/global-this';
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import createApolloClient from './js/util/apollo/createClient';
import hydrateState from './js/client/consumeHydrationState';
import configureStore from './js/configureStore';
import { createRouter, routes } from './js/router';
import resolveCurrentScreen, { RedirectError } from './js/router/resolveCurrentScreen';
import App from './js/client/App';
import appLogger from './js/util/logging/appLogger';

function render() {
    const state = hydrateState();
    const history = createBrowserHistory();
    const apolloClient = createApolloClient({ initialState: state.apiCache });
    const store = configureStore({ initialState: state.applicationState });
    const router = createRouter({ client: apolloClient, routes });

    resolveCurrentScreen(router, history.location, store)
        .then(initialView => {
            const element = document.getElementById('wrapper');
            if(element) {
                const app = React.createElement(App, {
                    apolloClient,
                    history,
                    initialView,
                    router,
                    store
                });

                ReactDOM.hydrate(app, element);
                return app;
            }else{
                throw new Error('React unable to mount because element #wrapper is not available');
            }
        }).
        catch(error => {
            if(error instanceof RedirectError) {
                history.replace(error.url);
            }else{
                const errorMessage = 'Error resolving current screen';
                appLogger.error(errorMessage)(error);
            }
        });
}

render();
