import React from "react"
import { As, ComponentWithAs } from "./forwardRef.types"

const forwardRef = <Props extends object, Component extends As>(
    component: React.ForwardRefRenderFunction<
        any,
        Omit<React.ComponentPropsWithoutRef<Component>, "as" | keyof Props> &
            Props & {
                as?: As
            }
    >
) => {
    return React.forwardRef(component) as unknown as ComponentWithAs<
        Component,
        Props
    >;
};

export default forwardRef;