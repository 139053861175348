import forwardRef from "../../private/forwardRef";
import { paletteHelper, typographyHelper } from "../../utils";
import React, { SyntheticEvent } from "react";
import styled from "styled-components";
import { CrossMd } from "../../components/icons";

const FatCross = styled(CrossMd)`
    stroke-width: 1.5px;
`

type PillStyleProps = {
    disabled: boolean
}

const Pill = styled.span<PillStyleProps>`
    ${typographyHelper("subtitle02")}
    transform: translateX(-8px);
    max-width: 100%;
    margin: 0 8px 7px 0;
    display: flex;
    align-items: center;
    padding-left: 8px;
    height: 32px;
    background-color: ${({ disabled, theme }) => 
        disabled
            ? theme.palette.backgroundDisabled
            : theme.palette.backgroundSecondary};
    border-radius: 32px;
    border: ${({ disabled, theme }) => 
        disabled
            ? `${theme.border.width.thin} solid ${theme.palette.borderDisabled}`
            : "none"};

    &:focus {
        outline: none;
        ${({ disabled, theme }) => 
            !disabled &&
            `box-shadow: inset 0 0 0 2px ${theme.palette.borderFocused};`}
    }
`

const PillText = styled.span<PillStyleProps>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 30px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
    ${({ disabled, theme }) =>
        disabled && `color: ${theme.palette.textDisabled}`};
`

const DeleteButton = styled.button.attrs({
    children: <FatCross />
})<PillStyleProps>`
    color: ${({ disabled, theme }) => 
        disabled ? theme.palette.textDisabled : "inherit"};
    background-color: transparent;
    border: none;
    padding: 0;
    border-radius: 100px;
    height: 24px;
    width: 24px;
    flex-shrink: 0;
    margin: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

    &:focus {
        background-color: ${paletteHelper("backgroundHoveredSecondary")};
        outline: none;
    }

    @media (hover: hover) {
        &:hover {
            ${({ disabled, theme }) => 
                !disabled &&
                `background-color: ${theme.palette.backgroundHoveredSecondary};`}
            outline: none;
        }
    }
`

type PillDangerouslySetClassNames = {
    pill?: string
    pillLabel?: string
    pillDeleteButton?: string
}

type InputPillProps = {
    accessibleLabel: string
    onDelete: (e: SyntheticEvent) => void
    name: string
    dangerouslySetClassNames: PillDangerouslySetClassNames
    disabled: boolean
    children: string
}

const InputPill = forwardRef<InputPillProps, "span">(
    (
        {
            children,
            accessibleLabel,
            onDelete,
            name,
            dangerouslySetClassNames,
            disabled,
            ...props
        },
        ref
    ) => {
        return (
            <Pill
                {...props}
                ref={ref}
                disabled={disabled}
                {...(disabled && { tabIndex: -1 })}
            >
                <PillText
                    disabled={disabled}
                    aria-label={accessibleLabel}
                    className={dangerouslySetClassNames?.pillLabel}
                >
                    {children}
                </PillText>
                <DeleteButton
                    type="button"
                    aria-label={`Remove ${accessibleLabel}`}
                    disabled={disabled}
                    onClick={onDelete}
                    className={dangerouslySetClassNames?.pillDeleteButton}
                />
                <input type="hidden" name={name} value={children} />
            </Pill>
        )
    }
)

export default InputPill;