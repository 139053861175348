import React, {
    useContext,
    useEffect,
    useState,
    useRef,
    useLayoutEffect,
    ReactElement
} from "react"
import TabsContext from "./tabs-context"
import styled, { ThemeContext } from "styled-components"
import { ResizeSensor } from "css-element-queries"
import { ArrowLeftMd, ArrowRightMd } from "../icons"
import composeRefs from "@seznam/compose-react-refs"
import {
    HorizontalList,
    ChevronWrapper,
    FadeySpacer,
    ChevronButton
} from "./styles"
import { spacingHelper } from "../../utils";
import forwardRef from "../../private/forwardRef";
import { TabListProps, TabListDangerouslySetClassNames } from "./tabs.types"

const scrollButtonVisibilityThreshold = 3

interface ScrollControlProps extends React.HTMLAttributes<HTMLButtonElement> {
    position: "left" | "right"
    backgroundColor: string
    visible: boolean
    dangerouslySetClassNames?: TabListDangerouslySetClassNames
}

const ScrollControl: React.FC<ScrollControlProps> = props => {
    const {
        children,
        position,
        backgroundColor,
        visible,
        onClick,
        dangerouslySetClassNames
    } = props
    const { hideTabBarLine } = useContext(TabsContext)
    return (
        <ChevronWrapper
            position={position}
            visible={visible}
            className={dangerouslySetClassNames?.[`${position}ChevronWrapper`]}
        >
            {position === "right" && (
                <FadeySpacer 
                    backgroundColor={backgroundColor}
                    position={position}
                    className={dangerouslySetClassNames?.rightFadeySpacer}
                />
            )}
            <ChevronButton
                tabIndex={-1}
                onClick={onClick}
                backgroundColor={backgroundColor}
                hideTabBarLine={hideTabBarLine}
                aria-label={`Scroll ${position}`}
                className={dangerouslySetClassNames?.[`${position}ScrollControl`]}
            >
                {children}
            </ChevronButton>
            {position === "left" && (
                <FadeySpacer
                    backgroundColor={backgroundColor}
                    position={position}
                    className={dangerouslySetClassNames?.leftFadeySpacer}
                />
            )}
        </ChevronWrapper>
    )
}

const TabListWrapper = styled.div`
    height: ${spacingHelper("threeExtraLarge")};
    position: relative;
`

const defaultProps = {
    buttonColor: "backgroundPrimary"
}

const TabList = forwardRef<TabListProps, "ul">((props, externalRef) => {
    const {
        className,
        style,
        css,
        buttonColor,
        dangerouslySetClassNames,
        children,
        ...remainingProps
    } = { ...defaultProps, ...props }

    const { selectedTab, setSelectedTab, variant } = useContext(TabsContext)
    const theme = useContext(ThemeContext)

    const [tabWidths, setTabWidths] = useState<number[]>([])
    const [tabPositions, setTabPositions] = useState<number[]>([])
    const [focusedTab, setFocusedTab] = useState<number | null>(null)
    const [showLeftScrollButton, setShowLeftScrollButton] = useState<boolean>(false)
    const [showRightScrollButton, setShowRightScrollButton] = useState<boolean>(false)

    const ulRef = useRef<HTMLUListElement>(null)
    const selectedFloatyLineRef = useRef<HTMLDivElement>(null)
    const snapshot = useRef<DOMRect | null>(null)

    useEffect(() => {
        const ul = ulRef.current
        if(!ul) return

        const selectedTabRightPosition = tabPositions[selectedTab] + tabWidths[selectedTab] - ul.getBoundingClientRect().left
        const selectedTabLeftPosition = tabPositions[selectedTab] - ul.getBoundingClientRect().left

        const chevronButtonWidth = parseFloat(theme.spacing.twoExtraLarge) * 16

        const leftOutOfView = selectedTabLeftPosition < chevronButtonWidth

        const rightOutOfView = selectedTabRightPosition > ul.clientWidth - chevronButtonWidth

        if(rightOutOfView || leftOutOfView) {
            ul.scrollLeft = tabWidths.slice(0, selectedTab).reduce((a, i) => a + i, 0) - chevronButtonWidth
        }

    }, [tabPositions, tabWidths, selectedTab, ulRef, theme])

    useEffect(() => {
        if(!ulRef.current) return

        const listItems: NodeListOf<HTMLElement> = ulRef.current.querySelectorAll("li > [role='tab']")

        const newTabWidths = Array.prototype.map.call(
            listItems,
            listItem => listItem.getBoundingClientRect().width
        ) as number[]

        const newTabPositions: number[] = Array.prototype.map.call(
            listItems,
            (listItem: HTMLElement) => listItem.getBoundingClientRect().left
        ) as number[]

        setTabWidths(newTabWidths)
        setTabPositions(newTabPositions)
    }, [ulRef, selectedTab])

    useEffect(() => {
        const ul = ulRef.current
        if (!ul) return

        const shouldShowLeftScrollButton = () => ul.scrollLeft > scrollButtonVisibilityThreshold
        const shouldShowRightScrollButton = () => Math.round(ul.scrollLeft) < ul.scrollWidth - ul.clientWidth - scrollButtonVisibilityThreshold

        const updateScrollButtonVisibility = () => {
            setShowLeftScrollButton(shouldShowLeftScrollButton())
            setShowRightScrollButton(shouldShowRightScrollButton())
        }

        const updateSelectedTabPositionSnapshot = () => {
            snapshot.current = selectedFloatyLineRef.current?.getBoundingClientRect() || null
        }

        new ResizeSensor(ul, () => {
            updateScrollButtonVisibility()
            updateSelectedTabPositionSnapshot()
        })

        ul.addEventListener("scroll", ()=> {
            updateScrollButtonVisibility()
            updateSelectedTabPositionSnapshot()
        }, {
            passive: true
        })
    }, [ulRef])

    useEffect(() => {
        snapshot.current = selectedFloatyLineRef.current?.getBoundingClientRect() || null
    }, [])

    useLayoutEffect(() => {
        const newBoundingBox = selectedFloatyLineRef.current?.getBoundingClientRect()

        if(!snapshot.current || !selectedFloatyLineRef.current || !newBoundingBox) 
            return

        const translateX = snapshot.current.left - newBoundingBox.left
        const scaleX = snapshot.current.width / newBoundingBox.width
        selectedFloatyLineRef.current.style.transition = ""
        selectedFloatyLineRef.current.style.transform = `translateX(${translateX}px scaleX(${scaleX})`

        snapshot.current = newBoundingBox

        requestAnimationFrame(() => {
            if(selectedFloatyLineRef.current) {
                selectedFloatyLineRef.current.style.transition = "transform 0.2s"
                selectedFloatyLineRef.current.style.transform = ""
            }
        })
    }, [selectedTab])

    const scrollLeft = () => {
        if(ulRef.current) {
            ulRef.current.scrollLeft = ulRef.current.scrollLeft - ulRef.current.clientWidth * 0.75
        }
    }

    const scrollRight = () => {
        if (ulRef.current) {
            ulRef.current.scrollLeft = ulRef.current.scrollLeft + ulRef.current.clientWidth * 0.75
        }
    }

    const childrenArray = React.Children.toArray(children)
    const numberOfChildren = childrenArray.length
    const indexOfLastTab = numberOfChildren - 1

    const truthyValues = (v: any) => v
    const newChildren = childrenArray.filter(truthyValues).map((child, index) => {
        return React.cloneElement(child as ReactElement, {
            index,
            variant,
            selected: index === selectedTab,
            focused: index === focusedTab,
            floatyLineRef: index === selectedTab ? selectedFloatyLineRef : null,
            activateTab: () => setSelectedTab(index),
            focusTab: (offset: number) => {
                let indexOfNewTab = index + offset
                if(indexOfNewTab > indexOfLastTab) {
                    indexOfNewTab = 0
                }else if(indexOfNewTab < 0) {
                    indexOfNewTab = indexOfLastTab
                }
                setFocusedTab(indexOfNewTab)
            },
            width: 100 / numberOfChildren
        })
    })

    return (
        <TabListWrapper className={className} style={style} css={css}>
            <ScrollControl
                position="left"
                visible={showLeftScrollButton}
                onClick={() => scrollLeft()}
                backgroundColor={buttonColor}
                dangerouslySetClassNames={dangerouslySetClassNames}
            >
                <ArrowLeftMd className={dangerouslySetClassNames?.leftScrollIcon} />
            </ScrollControl>
            <HorizontalList
                ref={composeRefs(ulRef, externalRef)}
                role="tablist"
                className={dangerouslySetClassNames?.tabList}
                {...remainingProps}
            >
                {newChildren}
            </HorizontalList>
            <ScrollControl
                position="right"
                visible={showRightScrollButton}
                onClick={() => scrollRight()}
                backgroundColor={buttonColor}
                dangerouslySetClassNames={dangerouslySetClassNames}
            >
                <ArrowRightMd className={dangerouslySetClassNames?.rightScrollIcon} />
            </ScrollControl>
        </TabListWrapper>
    )
})

TabList.displayName = "TabList"

export default TabList