import { css } from "styled-components";
import { paletteHelper, spacingHelper } from "../../../../@nitty";

export const containerClassName = "Container";

export const dangerouslySetClassNames = {
    textInputAndListboxContainer: "InputListboxContainer",
    inputBorder: "InputBorder",
    scrollableArea: "ScrollableArea",
    contentWrapper: "ContentWrapper",
    input: "Input",
    listboxWrapper: "ListboxWrapper",
    listbox: "Listbox",
    listboxOption: "ListboxOption",
    noResultsMessage: "NoResultsOption",
    pill: "Pill",
    pillDeleteButton: "PillDeleteButton"
};

export const suggestionBoxStyles = css`
    .${dangerouslySetClassNames.textInputAndListboxContainer} {
        border-radius: 1.5rem;
        border: 0.0625rem solid var(--nitty-borderPrimary);
        background-color: ${paletteHelper("backgroundPrimaryBase")};
    }
    .${dangerouslySetClassNames.inputBorder} {
        background: none;
        border-radius: unset;
        ::after {
            border: none;
            border-radius: unset;
        }
    }
    .${dangerouslySetClassNames.scrollableArea} {
        padding: 0.5rem 1.5rem 0;
    }
    .${dangerouslySetClassNames.listboxWrapper} {
        padding: unset;
        position: static;
    }
    .${dangerouslySetClassNames.listbox} {
        border: none;
        background: none;
        padding: 0.5rem 1rem 1rem;
        max-height: none;
    }
    .${dangerouslySetClassNames.listboxOption} {
        border-radius: 1.5rem;
    }
`;

export const modalSuggestionBoxStyles = css`
    .${containerClassName} {
        height: 100%;
    }
    .${dangerouslySetClassNames.textInputAndListboxContainer} {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .${dangerouslySetClassNames.input} {
        margin-bottom: 1rem;
    }
    .${dangerouslySetClassNames.inputBorder} {
        border-radius: unset;
        ::after {
            border: none;
            border-radius: 0;
            border-bottom: ${({ theme }) => theme.border.width.thin} solid ${paletteHelper("borderSecondary")};
        }
    }
    .${dangerouslySetClassNames.scrollableArea} {
        padding: ${spacingHelper("small 3.5rem twoExtraSmall medium")};
    }
    .${dangerouslySetClassNames.listboxWrapper} {
        top: 0;
        overflow: auto;
        position: relative;
        transform: none;
    }
    .${dangerouslySetClassNames.listbox} {
        border: none;
        max-height: 100%;
        padding: 0.5rem 1rem 1rem;
    }
    .${dangerouslySetClassNames.listboxOption} {
        border-radius: 1.5rem;
    }
`;
