import React, {
    MouseEvent,
    KeyboardEvent,
    useContext,
    useEffect,
    useRef
} from "react"
import { HorizontalListItem, TabButton, FloatyLine } from "./styles"
import TabsContext from "./tabs-context"
import forwardRef from "../../private/forwardRef"
import { TabProps } from "./tabs.types"
import composeRefs from "@seznam/compose-react-refs"

const Tab = forwardRef<TabProps, "button">(
    (
        {
            index,
            selected,
            focused,
            activateTab,
            focusTab,
            variant,
            width,
            floatyLineRef,
            onClick,
            onKeyDown,
            dangerouslySetClassNames,
            children,
            ...props
        },
        externalRef
    ) => {
        const { underlineColor } = useContext(TabsContext)

        const focusRef = useRef<HTMLButtonElement>(null)
        useEffect(() => {
            if(focused) focusRef.current?.focus()
        }, [focused])

        return (
            <HorizontalListItem
                role="presentation"
                key={index}
                variant={variant || "align-left"}
                width={`${width}%`}
                className={dangerouslySetClassNames?.listItem}
            >
                <TabButton
                    role="tab"
                    aria-selected={selected}
                    onClick={(event: MouseEvent<HTMLButtonElement>) => {
                        activateTab?.()
                        typeof onClick === "function" && onClick(event)
                    }}
                    onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                        if(event.key === "ArrowRight") {
                            event.preventDefault()
                            focusTab?.(1)
                        }else if(event.key === "ArrowLeft") {
                            event.preventDefault()
                            focusTab?.(-1)
                        }
                        typeof onKeyDown === "function" && onKeyDown(event)
                    }}
                    tabIndex={selected ? 0 : -1}
                    ref={composeRefs(focusRef, externalRef)}
                    selected={selected}
                    variant={variant}
                    {...props}
                    className={dangerouslySetClassNames?.tabButton}
                >
                    {children}
                </TabButton>
                <FloatyLine
                    ref={floatyLineRef}
                    active={!!selected}
                    underlineColor={underlineColor}
                    className={dangerouslySetClassNames?.floatyLine}
                />
            </HorizontalListItem>
        )
    }
)

Tab.displayName = "Tab";

export default Tab;