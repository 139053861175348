import React, { FC, ReactElement, ReactNode } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { NearbitesTheme } from 'js/@nearbites.com/types';
import { Nitty, Montserrat } from '../../@nitty';
import Header from './Header';
import Footer from './Footer';
import { LoadingIndicator } from './LoadingIndicator';

const HEADERLESS = 'HEADERLESS';
const FOOTERLESS = 'FOOTERLESS';
const BLANK = 'BLANK';

const MainContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const DefaultLayout: FC<{
    children?: ReactNode;
    searchHeader?: ReactElement;
}> = ({
    children,
    searchHeader
}) => {
    return (
        <>
            <Header searchHeader={searchHeader} />
            <MainContent>
                {children}
            </MainContent>
            <Footer />
        </>
    );
};

const HeaderlessLayout: FC = ({ children }) => {
    return (
        <>
            <MainContent>
                {children}
            </MainContent>
            <Footer />
        </>
    );
};

const FooterlessLayout: FC = ({ children }) => {
    return (
        <>
            <Header />
            <MainContent>
                {children}
            </MainContent>
        </>
    );
};

const LayoutComponent: FC<{
    children?: ReactNode;
    layout?: string;
    searchHeader?: ReactElement;
}> = ({ layout, children, searchHeader }) => {
    switch (layout) {
        case HEADERLESS:
            return <HeaderlessLayout>{children}</HeaderlessLayout>;
        case FOOTERLESS:
            return <FooterlessLayout>{children}</FooterlessLayout>;
        case BLANK:
            return <>{children}</>;
        default:
            return <DefaultLayout searchHeader={searchHeader}>{children}</DefaultLayout>;
    }
};

const appTheme = (theme: NearbitesTheme) => ({
    ...theme,
    layoutBreakpoints: {
        small: '@media only screen and (min-width: 350px)',
        medium: '@media only screen and (min-width: 501px)',
        large: '@media only screen and (min-width: 1000px)',
    },
    maxWidth: "2520px",
    bodyMaxWidth: "90rem"
});

const LayoutThemeProvider: FC = ({ children }) => (
    <ThemeProvider theme={appTheme}>{children}</ThemeProvider>
);

const Layout: FC<{
    layout?: string;
    isLoading?: boolean;
    elements?: {
        searchHeader?: ReactElement
    }
}> = ({
    children,
    layout,
    isLoading,
    elements
}) => {
    const darkMode = false;

    const NittyProps = {
        darkMode,
    };

    const searchHeader = elements?.searchHeader;

    return (
        <Nitty {...NittyProps}>
            <Montserrat />
            <LayoutThemeProvider>
                { isLoading && <LoadingIndicator />}
                <LayoutComponent layout={layout} searchHeader={searchHeader}>
                    {children}
                </LayoutComponent>
            </LayoutThemeProvider>
        </Nitty>
    );
};

export default Layout;
