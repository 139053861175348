import styled from "styled-components";
import { LinkBaseProps, LinkVariant } from "./link.types";
import { NittyTheme } from "../nitty/types";

type ColorHelperProps = {
    theme: NittyTheme
    $variant: LinkVariant
    disabled: boolean
}

const colorHelper = (state?: "hover") => ({ theme, $variant, disabled}: ColorHelperProps): string => {
    const {palette} = theme;
    const standardColors = {
        primary: palette.linkPrimary,
        secondary: palette.linkSecondary,
        inline: palette.linkPrimary
    }
    if(disabled) return palette.textDisabled
    else if(state === "hover") return palette.linkPrimary
    else return standardColors[$variant]
}

type TextDecorationHelperProps = {
    $variant: LinkVariant
    disabled: boolean
}

const textDecorationHelper = (state?: "hover") => ({ $variant, disabled } : TextDecorationHelperProps): string => {
    const standardTextDecorations = {
        primary: "none",
        secondary: "underline",
        inline: "underline"
    }

    if(disabled) return standardTextDecorations[$variant]
    else if(state === "hover") return "underline"
    else return standardTextDecorations[$variant]
}

const LinkBase = styled.a<LinkBaseProps>`
   font-family: ${({ theme }) => theme.typography.body01.fontFamily};
   font-weight: ${({ theme, $variant }) => $variant === "secondary" ? "inherit" : theme.typography.strong.fontWeight};
   text-decoration: ${textDecorationHelper()};
   color: ${colorHelper()};

   @media (hover: hover) {
    &:hover {
        text-decoration: ${textDecorationHelper("hover")};
        color: ${colorHelper("hover")};
        cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
    }
   }

   &:focus {
    outline: ${({ theme }) => `${theme.border.width.thick} solid ${theme.palette.borderFocused}`};
    outline-offset: 0.125rem;
   }

   &:focus-visible {
    outline: ${({ theme }) => `${theme.border.width.thick} solid ${theme.palette.borderFocused}`};
    outline-offset: 0.125rem;
   }

   &:focus:not(:focus-visible) {
    outline: none;
   }
`

export default LinkBase;
