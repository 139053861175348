const formatRegex = /[,-]format=[a-z]+/i;

const getOptionsString = ({ format, options }) => {
    const allOptions = [];
    if(options) {
        allOptions.push(options);
    }
    if(format) {
        allOptions.push(`format=${format}`)
    }
    if(allOptions.length > 0) {
        return `-${allOptions.join(',')}`;
    }else{
        return '';
    }
}

export const getSrcFromTemplatedUrl = ({
    templatedUrl,
    size,
    format,
    options
}) => {
    if(templatedUrl) {
        const optionsString = getOptionsString({ format, options });
        if(!optionsString) {
            return templatedUrl.replace('{size}', size);
        }
        if(formatRegex.test(templatedUrl)) {
            return templatedUrl.replace('{size}', size).replace(formatRegex, optionsString);
        }
        return templatedUrl.replace('{size}', `${size}${optionsString}`);
    }

    return null;
};
