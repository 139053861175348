import styled from "styled-components"
import { spacingHelper, typographyHelper } from "../../../utils"
import { NittyTheme } from "../../nitty/types"
import { SelectSize } from "./select.types"
import Label from "../../label";

type HelperProps = {
    theme: NittyTheme
    sizeVariant: SelectSize
}

const heightHelper = ({ theme, sizeVariant }: HelperProps) => {
    const { spacing } = theme;
    const height = {
        large: spacing.extraLarge,
        medium: spacing.large,
        small: spacing.large
    }
    return height[sizeVariant] || spacing.extraLarge;
}

const marginBottomHelper = ({ sizeVariant, theme }: HelperProps) => {
    const { spacing } = theme;

    const margins = {
        large: spacing.extraSmall,
        medium: spacing.extraSmall,
        small: spacing.twoExtraSmall
    }
    return margins[sizeVariant];
}

const StyledLabel = styled(Label)`
    margin-bottom: ${spacingHelper("twoExtraSmall")};
`

interface ChevronWrapperProps extends HelperProps {
    isOpen: boolean
    disabled: boolean
}

const ChevronWrapper = styled.div<ChevronWrapperProps>`
    align-items: center;
    display: flex;
    height: ${heightHelper};
    margin-left: ${spacingHelper("medium")};
    pointer-events: none;
    transition: transform 0.2s;
    ${({ isOpen }) => isOpen && `transform: rotate(-180deg)`}
    ${({ disabled, theme }) => 
        disabled && `color: ${theme.palette.textDisabled};`}
`

interface IconWrapperProps extends HelperProps {
    disabled: boolean;
}

const IconWrapper = styled.div<IconWrapperProps>`
    margin-right: ${({ sizeVariant, theme }) => 
        sizeVariant === "small" ? theme.spacing.small : theme.spacing.medium};
    align-items: center;
    display: flex;
    height: ${heightHelper};
    ${({ disabled, theme }) => 
        disabled && `color: ${theme.palette.textDisabled};`}
`

const ComponentContainer = styled.div`
    text-align: left;

    & > * + * {
        position: relative;
        cursor: pointer;
    }
`

interface DisplayContainerProps extends HelperProps {
    isPlaceholder: boolean
    disabled: boolean
}

const DisplayContainer = styled.div<DisplayContainerProps>`
    text-align: left;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    margin-bottom: ${marginBottomHelper};
    height: ${heightHelper};
    ${({ sizeVariant }) =>
        typographyHelper(sizeVariant === "small" ? "body02" : "body01")}

    & > * {
        ${({ isPlaceholder, theme }) =>
            isPlaceholder && `color: ${theme.palette.textSecondary};`}
        ${({ disabled, theme }) => 
            disabled && `color: ${theme.palette.textDisabled};`}
        line-height: ${heightHelper};
    }
`

export {
    StyledLabel,
    ChevronWrapper,
    IconWrapper,
    DisplayContainer,
    ComponentContainer
}