const HOME_SCREEN = 'HomeScreen';
const SEARCH_RESULTS_SCREEN = 'SearchResultsScreen';
const MEAL_DETAILS_SCREEN = 'MealDetailsScreen';
const RESTAURANT_DETAILS_SCREEN = 'RestaurantDetailsScreen';
const ERROR_SCREEN = 'ErrorScreen';

export {
    HOME_SCREEN,
    SEARCH_RESULTS_SCREEN,
    MEAL_DETAILS_SCREEN,
    RESTAURANT_DETAILS_SCREEN,
    ERROR_SCREEN
};
