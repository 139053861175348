import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserHistoryContext }  from './history';
import Screen from './Screen';

const App = (props) => {
    const {
        apolloClient,
        history,
        initialView,
        router,
        store
    } = props;

    return (
        <ApolloProvider client={apolloClient}>
                <Provider store={store}>
                    <BrowserHistoryContext.Provider value={history}>
                        <Screen
                            history={history}
                            initialView={initialView}
                            router={router}
                            store={store}
                        />
                    </BrowserHistoryContext.Provider>
                </Provider>
        </ApolloProvider>
    );
};

export default hot(App);
