import { updateSearchQuery } from "../../../../@nearbites.com/query/search-query";
import React, { FunctionComponent } from "react";
import { GpsLocation, SearchQuery, Localities } from "../../../../@nearbites.com/query";
import { GpsMd } from "../../../../@nitty";
import FilterPill from "../filter-pill";

type Props = {
    onClick: (searchQuery: SearchQuery) => void;
    searchQuery: SearchQuery
}

const getSelectedState = (searchQuery: SearchQuery): boolean => "gpsLocationSearch" in searchQuery.filters.location;

const GpsPill: FunctionComponent<Props> = ({
    onClick,
    searchQuery,
    ...props
}) => {
    const handleGpsToggle = () => {
        if (!getSelectedState(searchQuery)) {
            navigator.geolocation.getCurrentPosition(({ coords }) => {
                const { latitude, longitude } = coords;

                const updatedLocation: GpsLocation = {
                    gpsLocationSearch: [longitude, latitude]
                };

                const updatedSearchQuery = updateSearchQuery({
                    location: updatedLocation
                })(searchQuery);

                onClick(updatedSearchQuery)
            })
        }else{
            const updatedLocation: Localities = {
                searchLocations: []
            };

            const updatedSearchQuery = updateSearchQuery({
                location: updatedLocation
            })(searchQuery);

            onClick(updatedSearchQuery)
        }
    }

    return (
        <FilterPill
            onClick={handleGpsToggle}
            selected={getSelectedState(searchQuery)}
            hideLabel={true}
            icon={<GpsMd />}
            {...props}
        />
    )
}

export default GpsPill;
