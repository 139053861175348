import UniversalRouter from 'universal-router';

const createRouter = ({ client, routes }) => 
    new UniversalRouter(routes, {
        context: {
            client
        }
    });

export default createRouter;
