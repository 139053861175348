import { pxToRem } from "../../private/helpers"
import { NittyTheme } from "../nitty/types"
import { ButtonSize, ButtonVariant } from "./button.types"

type PaddingHelperProps = {
    theme: NittyTheme
    $sizeVariant: ButtonSize
    $compact: boolean
    $variant: ButtonVariant
    disabled: boolean
}

const paddingHelper = ({
    theme,
    $sizeVariant,
    $compact,
    $variant,
    disabled,
}: PaddingHelperProps):string => {
    const { spacing, border } = theme
    const borderWidth = border.width.thin
    const hasBorder = $variant === "outline" || (disabled && $variant !== "naked")
    const padding = {
        large: hasBorder
            ? `calc(${spacing.small} - ${borderWidth}) calc(${spacing.large} - ${borderWidth})`
            : `${spacing.small} ${spacing.large}`,
        medium: hasBorder
            ? `calc(${spacing.extraSmall} - ${borderWidth}) calc(${spacing.large} - ${borderWidth})`
            : `${spacing.extraSmall} ${spacing.large}`,
        small: hasBorder
            ? `calc(${pxToRem(6)} - ${borderWidth}) calc(${spacing.medium} - ${borderWidth})`
            : `${pxToRem(6)} ${spacing.medium}`,
    }
    const compactPadding = {
        large: hasBorder
            ? `calc(${spacing.small} - ${borderWidth}) calc(${spacing.large} - ${borderWidth})`
            : `${spacing.small} ${spacing.large}`,
        medium: hasBorder
            ? `calc(${spacing.extraSmall} - ${borderWidth})`
            : `${spacing.extraSmall} ${spacing.large}`,
        small: hasBorder
            ? `calc(${pxToRem(6)} - ${borderWidth})`
            : `${spacing.extraSmall}`,
    }

    return $compact ? compactPadding[$sizeVariant] : padding[$sizeVariant];
}

type ColorHelperProps = {
    $variant: ButtonVariant
    disabled?: boolean
    theme: NittyTheme
}

const colorHelper = ({
    $variant, 
    disabled, 
    theme
}: ColorHelperProps):string => {
    const standardColors = {
        primary: theme.palette.textInvertedStatic,
        outline: theme.palette.textPrimary,
        secondary: theme.palette.textInverted,
        naked: theme.palette.textPrimary
    }
    return disabled ? theme.palette.textDisabled : standardColors[$variant]
}

const backgroundColorHelper = (state?: "hover") => ({ $variant, disabled, theme }: ColorHelperProps):string => {
    const standardColors = {
        primary: theme.palette.backgroundBrandStatic,
        outline: "transparent",
        secondary: theme.palette.backgroundPrimaryInverted,
        naked: "transparent"
    }
    const disabledColors = {
        primary: theme.palette.backgroundDisabled,
        outline: theme.palette.backgroundDisabled,
        secondary: theme.palette.backgroundDisabled,
        naked: "transparent"
    }
    const hoverColors = {
        primary: theme.palette.backgroundHoveredBrandStatic,
        outline: theme.palette.backgroundHoveredPrimary,
        secondary: theme.palette.backgroundHoveredPrimaryInverted,
        naked: theme.palette.backgroundHoveredPrimary,
    }
    if(disabled) return disabledColors[$variant]
    else if (state === "hover") return hoverColors[$variant]
    else return standardColors[$variant]
}

const borderHelper = (state?: "hover" | "focus") => ({ $variant, disabled, theme }: ColorHelperProps): string | undefined => {
    const { border, palette } = theme;
    const outlineBorders = {
        standard: `${border.width.thin} solid ${palette.borderPrimary}`,
        disabled: `${border.width.thin} solid ${palette.borderDisabled}`,
        focus: `${border.width.thick} solid ${palette.borderFocused}`,
        hover: `${border.width.thin} solid ${palette.borderHovered}`,
    }
    if (state === "focus") return outlineBorders.focus
    if ($variant === "naked") return undefined
    if (disabled) return outlineBorders.disabled
    if($variant !== "outline") return undefined
    else if(state === "hover") return outlineBorders.hover
    else return outlineBorders.standard
}

const focusPositionHelper = ({ $variant }: {$variant: ButtonVariant}) => {
    if($variant === "naked") return "0"
    if($variant === "outline") return pxToRem(-6)
    else return pxToRem(-5)
}

type MinSizeHelperProps = {
    $sizeVariant: ButtonSize
    theme: NittyTheme
}

const minSizeHelper = ({ $sizeVariant, theme }: MinSizeHelperProps) => {
    const { spacing } = theme;
    const minSize = {
        large: spacing.threeExtraLarge,
        medium: spacing.twoExtraLarge,
        small: spacing.extraLarge
    }
    return minSize[$sizeVariant]
}

export {
    paddingHelper,
    backgroundColorHelper,
    colorHelper,
    borderHelper,
    focusPositionHelper,
    minSizeHelper,
}