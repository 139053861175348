import { paletteHelper } from "../../@nitty";
import styled, { keyframes } from "styled-components";

const slide = keyframes`
    from {
        transform: scaleX(0);
    }

    to {
        transform: scaleX(0.9);
    }
`

const crawl = keyframes`
    from {
        transform: scaleX(0.9);
    }

    to {
        transform: scaleX(0.99);
    }
`;

const ANIMATION_DELAY = "100";
const ANIMATION_DURATION = "1000";
const ANIMATION_UNITS = "ms";

export const LoadingIndicator = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 2px;
    width: 100vw;
    z-index: 1000;

    &::before,
    &::after {
        content: "";
        display: block;
        height: 2px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: ${paletteHelper("backgroundBrandStatic")};
        transform-origin: top left;
        transform: scaleX(0);
    }

    &::before {
        animation-name: ${slide};
        animation-duration: ${ANIMATION_DURATION + ANIMATION_UNITS};
        animation-timing-function: cubic-bezier(0.8, 0, 0.8, 1);
        animation-delay: ${ANIMATION_DELAY + ANIMATION_UNITS};
        animation-fill-mode: both;
    }

     &::after {
        animation-name: ${crawl};
        animation-duration: 10s;
        animation-timing-function: ease-out;
        animation-delay: ${ANIMATION_DURATION + ANIMATION_DELAY + ANIMATION_UNITS};
        animation-fill-mode: forwards; 
    }
`