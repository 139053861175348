import React, { FocusEvent, FormEvent, useState } from "react";
import { useAutoId } from "../../private/helpers";
import composeRefs from "@seznam/compose-react-refs";
import {
    TextInputBaseSize,
    TextInputBaseSquaredCorners,
    TextInputBaseProps,
    TextInputPropsProp
} from "text-input-base.types";
import { CrossMd } from "../icons";
import forwardRef from "../../private/forwardRef";
import { 
    Input,
    InputBorder,
    ScrollableArea,
    ValidationError,
    HelpText,
    ClearButton
} from "./text-input-base.styles";


const defaultProps = {
    sizeVariant: "large" as TextInputBaseSize,
    autoValidate: true,
    disabled: false,
    type: "text",
    squaredCornersPosition: "none" as TextInputBaseSquaredCorners,
    textInputProps: {} as TextInputPropsProp,
    maxHeight: "100px",
    inputMinWidth: "0",
    showClearButton: false
}

const TextInputBase = forwardRef<TextInputBaseProps, "input">(
    (props, externalRef) => {
        const {
            id,
            type,
            beforeNode,
            afterNode,
            disabled,
            autoValidate,
            onBlur,
            onFocus,
            onInvalid,
            errorMessage,
            helpMessage,
            sizeVariant,
            textInputBorderProps,
            textInputProps,
            squaredCornersPosition,
            renderInput: userRenderInput,
            maxHeight,
            dangerouslySetClassNames,
            showClearButton,
            onClear,
            ...remainingProps
        } = { ...defaultProps, ...props }

        const [focused, setFocused] = useState(false);
        const [errorMessageText, setErrorMessageText] = useState<
            string | undefined
        >(undefined);

        const { onBlur: textInputPropsOnBlur, ...remainingTextInputProps } = textInputProps;

        const internalRef = React.createRef<HTMLInputElement>();

        const autoId = useAutoId(id);

        const validationMessageText = () => {
            return errorMessage || errorMessageText;
        }
        const showErrorMessage = !!validationMessageText()
        const showHelpMessage = !!helpMessage && !validationMessageText()

        const renderErrorMessage = () => {
            return (
                showErrorMessage && (
                    <ValidationError
                        role="alert"
                        id={`${autoId}-error`}
                        className={dangerouslySetClassNames?.errorMessage}
                    >
                        {validationMessageText()}
                    </ValidationError>
                )
            )
        }

        const renderHelpMessage = () => {
            return (
                showHelpMessage && (
                    <HelpText
                        disabled={disabled}
                        id={`${autoId}-help`}
                        className={dangerouslySetClassNames?.helpMessage}
                    >
                        {helpMessage}
                    </HelpText>
                )
            )
        }

        const renderInput = () => (
            <Input
                disabled={disabled}
                aria-describedby={
                    showErrorMessage 
                        ? `${autoId}-error`
                        : showHelpMessage
                        ? `${autoId}-help`
                        : undefined
                }
                {...remainingProps}
                sizeVariant={sizeVariant}
                id={autoId}
                type={type}
                onInvalid={(e: FormEvent<HTMLInputElement>) => {
                    e.preventDefault()
                    const target = e.target as HTMLObjectElement;
                    if(autoValidate) setErrorMessageText(target.validationMessage);
                    typeof onInvalid === "function" && onInvalid(e);
                }}
                onFocus={(e: FocusEvent<HTMLInputElement>) => {
                    setFocused(true);
                    typeof onFocus === "function" && onFocus(e);
                }}
                onBlur={(e: FocusEvent<HTMLInputElement>) => {
                    setFocused(false);
                    typeof onBlur === "function" && onBlur(e);
                    typeof textInputPropsOnBlur === "function" && textInputPropsOnBlur(e);
                    if (autoValidate) setErrorMessageText(e.target.validationMessage);
                }}
                {...remainingTextInputProps}
                ref={composeRefs(textInputProps?.ref, internalRef, externalRef)}
                className={dangerouslySetClassNames?.input}
            />
        )

        return (
            <>
                <InputBorder
                    onClick={() => {
                        if(internalRef.current) {
                            setFocused(true);
                            internalRef.current.focus()
                        }
                    }}
                    focused={focused}
                    errored={validationMessageText()}
                    disabled={disabled}
                    squaredCornersPosition={squaredCornersPosition}
                    {...textInputBorderProps}
                    className={dangerouslySetClassNames?.inputBorder}
                >
                    <ScrollableArea
                        data-modal-scroll-enable
                        maxHeight={maxHeight}
                        sizeVariant={sizeVariant}
                        className={dangerouslySetClassNames?.scrollableArea}
                    >
                        {beforeNode}

                        {userRenderInput?.({ input: renderInput(), ref: internalRef }) || renderInput()}

                        {showClearButton && (
                            <ClearButton
                                variant="naked"
                                hideLabel
                                compact
                                $sizeVariant={sizeVariant}
                                disabled={disabled}
                                icon={<CrossMd />}
                                onClick={onClear}
                                className={dangerouslySetClassNames?.clearButton}
                            >
                                Clear
                            </ClearButton>
                        )}
                        {afterNode}
                    </ScrollableArea>
                </InputBorder>
                {renderErrorMessage()}
                {renderHelpMessage(disabled)}
            </>
        )


    }
)

TextInputBase.displayName = "TextInputBase";

export default TextInputBase;