import mealDetailsQuery from './mealDetailsQuery.graphql';
import checkErrors from '../checkErrors';
import { createError } from '../createError';
import createVariables from './createVariables';

const hasResults = data => data != null;

const fetchData = ({ client, location }) => {
    return resultsFetchData({ resultsQuery: mealDetailsQuery })({ client, location });
};

const slugRegex = /^(?:.*[-/])?([a-zA-Z0-9-_]+)$/;

const extractMealId = (pathname) => {
    const matched = slugRegex.exec(pathname);
    return matched ? matched[1] : null;
}

const resultsFetchData = ({ resultsQuery }) => ({ client, location, fetchPolicy = 'cache-first' }) => {
    const { pathname, search, query } = location;
    const mealId = extractMealId(pathname);

    const searchParams = {
        mealId
    }

    return client
        .query({
            query: resultsQuery,
            variables: createVariables(searchParams),
            fetchPolicy
        })
        .then(checkErrors(hasResults))
        .catch((error) => {
            console.log(error.networkError.result)
            createError({ dataBeingFetched: `meals` })
        });
};

export default fetchData;
