import React, { createContext, useState } from "react"
import useMedia from "./hooks/useMedia";

const AppContext = createContext({});

const AppProvider = ({
    children,
    initialValue: { location },
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const { isSmallScreen } = useMedia();
    const mediaQueryList = {
        isSmallScreen
    };

    return(
        <AppContext.Provider
            value={{
                location,
                isLoading,
                setIsLoading,
                mediaQueryList
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export { AppProvider }
export default AppContext;
