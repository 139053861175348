import { IS_PRODUCTION } from '../../../config/environment';

export default function consumeHydrationState() {
    const state = {
        apiCache: APP.apiCache,
        applicationState: APP.applicationState
    };

    if(IS_PRODUCTION) {
        delete APP.apiCache;
        delete APP.applicationState;
    }

    return state;
}
