import { pxToRem } from "../../private/helpers"
import styled, { CSSObject } from "styled-components"
import { NittyTheme } from "../../../@nitty"
import { paletteHelper, spacingHelper } from "../../utils"
import { ListboxOptionProps, ListboxOptionSizeVariant } from "./listbox-option.types"
import { ElementPopoverPlacementVariant } from "../element-popover/element-popover.types"
import { AutocompleteSize } from "./autocomplete.types";
import Label from "../label"

type HeightHelperProps = {
    theme: NittyTheme
    sizeVariant: AutocompleteSize
}

const heightHelper = ({ theme, sizeVariant }: HeightHelperProps) => {
    const { spacing } = theme
    const height = {
        large: spacing.extraLarge,
        medium: spacing.large,
        small: spacing.large
    }
    return height[sizeVariant] || spacing.extraLarge
}

type PaddingHelperProps = {
    theme: NittyTheme
    sizeVariant: ListboxOptionSizeVariant
}

const paddingHelper = ({ theme, sizeVariant }: PaddingHelperProps) => {
    const {spacing } = theme;
    const padding = {
        large: `${pxToRem(11)} ${spacing.medium}`,
        medium: `${pxToRem(7)} ${spacing.medium}`,
        small: `${pxToRem(5)} ${spacing.small}`,
    }
    return padding[sizeVariant];
}

type MaxHeightHelperProps = {
    sizeVariant: ListboxOptionSizeVariant
    listboxMaxHeight: string
    visibleOptionsToDisplay: number
}

const maxHeightHelper = ({
    sizeVariant,
    listboxMaxHeight,
    visibleOptionsToDisplay
}: MaxHeightHelperProps) => {
    const maxHeight = {
        large: pxToRem(46),
        medium: pxToRem(40),
        small: pxToRem(30)
    }
    const optionHeight = maxHeight[sizeVariant];
    return visibleOptionsToDisplay
        ? `calc(${visibleOptionsToDisplay} * ${optionHeight} + (${optionHeight} * 0.5))`
        : listboxMaxHeight
}

type ListboxWrapperProps = {
    popperStyle: CSSObject
}

const ListboxWrapper = styled.div<ListboxWrapperProps>`
    z-index: 89;
    width: 100%;
    ${({ popperStyle }) => ({ ...popperStyle })}
`

type ListboxProps = {
    visible: boolean
    isErrored: boolean
    placement: ElementPopoverPlacementVariant
    sizeVariant: ListboxOptionSizeVariant
    listboxMaxHeight: string
    visibleOptionsToDisplay: number
    theme: NittyTheme
}

const Listbox = styled.ul<ListboxProps>`
    display: ${({ visible }) => (visible ? "block" : "none")};
    border: ${({ theme: { border, palette}, isErrored, visible }) =>
        isErrored
            ? `${border.width.thick} solid ${palette.borderErrored}`
            : visible && `${border.width.thick} solid ${palette.borderFocused}`};
    ${({ placement }) =>
        placement === "bottom" ? `border-top: none` : `border-bottom: none`};
    border-radius: ${({ theme, placement }) =>
        placement === "bottom"
            ? `0 0 ${theme.border.radius.medium} ${theme.border.radius.medium}`
            : `${theme.border.radius.medium} ${theme.border.radius.medium} 0 0`};
    background-color: ${paletteHelper("backgroundPrimaryBase")};
    width: 100%;
    padding: 0;
    margin: 0;
    max-height: ${maxHeightHelper};
    overflow: auto;
    outline: none;
`

const ListItem = styled.li`
    color: inherit;
    list-style-type: none;
    text-indent: 0;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
    padding: ${paddingHelper};
    font-size: ${({ sizeVariant, theme }) =>
        sizeVariant === "small"
            ? theme.typography.body02.fontSize
            : theme.typography.body01.fontSize };
    line-height: ${({ sizeVariant, theme }) =>
        sizeVariant === "small"
            ? theme.typography.body02.lineHeight
            : theme.typography.body01.lineHeight };
`

const ListboxOption = styled(ListItem)<ListboxOptionProps>`
    cursor: pointer;
    ${({ isActive, theme }) => {
        if(isActive) {
            return `
                background-color: ${theme.palette.backgroundFocusedPrimary};
                color: ${theme.palette.textPrimary};
            `
        }
    }}

    & p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`

const NoResultsListItem = styled(ListItem)`
    color: ${paletteHelper("textSecondary")}
`

const StyledLabel = styled(Label)`
    margin-bottom: ${spacingHelper("twoExtraSmall")};
`

const ComponentContainer = styled.div`
    text-align: left;

    & > * + * {
        position: relative;
    }
`

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`

interface IconWrapperProps extends HeightHelperProps {
    disabled: boolean
}

const IconWrapper = styled.div<IconWrapperProps>`
    align-items: center;
    display: flex;
    height: ${heightHelper};
    margin-right: ${spacingHelper("medium")};
    ${({ disabled, theme }) =>
        disabled && `color: ${theme.palette.textDisabled};`}
`

export {
    ListboxOption,
    ListboxWrapper,
    Listbox,
    NoResultsListItem,
    StyledLabel,
    ComponentContainer,
    ContentWrapper,
    IconWrapper
}
