import React, { FunctionComponent } from "react";
import { Dialog, Modal as BaseModal, H2 } from "../../@nitty";
import styled from "styled-components";

const DialogWrapper = styled.div`
    height: -webkit-fill-available;
    display: flex;

    .DialogBody {
        height: 100%;
    }

    @media only screen and (min-width: 600px) {
        .DialogBody {
            height: auto;
        }
    }
`

const StyledDialog = styled(Dialog)<{ maxWidth?: string }>`
    border-radius: unset;

    ${({ maxWidth }) => maxWidth ? `width: ${maxWidth};` : ""}

    @media only screen and (min-width: 600px) {
        border-radius: ${({ theme }) => theme.border.radius.large};
    }
`;

type ModalProps = {
    handleCloseModal: () => void
    modalMountID: HTMLElement | string,
};

const Modal: FunctionComponent<ModalProps> = ({
    children,
    hideDefaultCloseButton,
    openModal,
    handleCloseModal,
    modalMountID,
    ...dialogProps
}) => (
    <BaseModal
        opened={openModal ? true : false}
        onRequestClose={handleCloseModal}
        getAppElement={() => modalMountID}
        reactModalProps={{
            style: {
                overlay: {
                    alignItems: "flex-start"
                }
            }
        }}
    >
        <DialogWrapper>
            <StyledDialog
                {...dialogProps}
                autoFullScreen
                noMargins
                dangerouslySetClassNames={{
                    body: 'DialogBody'
                }}
                onClose={hideDefaultCloseButton ? null : handleCloseModal}
            >
                {children}
            </StyledDialog>
        </DialogWrapper>
    </BaseModal>
)

export default Modal;
