import getState from '../util/transactionId';

const error = ({ logger, getTransactionId, logLevel }) => (
    message,
    customAttributes
) => (error) => {
    const transactionId = getTransactionId();
    const output = {
        logtype: 'application',
        transaction_id: transactionId ? transactionId.transactionId : undefined,
        message,
        customAttributes,
        stack: error ? error.stack : undefined,
        logLevel
    };

    return logger(JSON.stringify(output));
};

const log = ({ logger, getTransactionId, logLevel }) => (message) => {
    const transactionId = getTransactionId();
    const output = {
        logtype: 'application',
        transaction_id: transactionId ? transactionId.transactionId : undefined,
        message,
        logLevel
    };

    return logger(JSON.stringify(output));
};

const createServerLogger = ({ logger = console, getTransactionId = getState } = {}) => {
    return {
        error: error({ logger: logger.error, getTransactionId, logLevel: 'error' }),
        log: log({ logger: logger.info, getTransactionId, logLevel: 'info' }),
        warn: log({ logger: logger.warn, getTransactionId, logLevel: 'warn' })
    }
};

export default createServerLogger;
