import { ChannelViewConfigurationType } from '../types';
import {
    mealSortTypes,
    priceOptions,
    mealTypes
} from './filter-configurations'

const meals: ChannelViewConfigurationType = {
    title: "Meals",
    sortTypes: mealSortTypes,
    priceOnly: false,
    filterOptions: {
        price: priceOptions,
        priceLabel: "price",
        mealTypes
    }
}

const restaurants: ChannelViewConfigurationType = {
    title: "Restaurants",
    sortTypes: mealSortTypes,
    priceOnly: false,
    filterOptions: {
        price: priceOptions,
        priceLabel: "price",
        mealTypes
    }
}

const ChannelViewConfiguration = {
    meals,
    restaurants,
    default: meals
}

export { ChannelViewConfiguration };
