import { Error as ErrorScreen } from '../screens/error';
import { success, notFound } from '../util/Response';
import {
    HOME_SCREEN,
    SEARCH_RESULTS_SCREEN,
    MEAL_DETAILS_SCREEN,
    RESTAURANT_DETAILS_SCREEN,
    ERROR_SCREEN
} from './types';

const defaultFetchData = () => Promise.resolve(success({}));
const notFoundFetchData = () => Promise.resolve(notFound);

const notFoundRoute = {
    path: '/(.*)',
    action: () => ({
        fetchData: notFoundFetchData,
        Screen: ErrorScreen,
        screenName: ERROR_SCREEN
    })
};

const searchResultsAction = (context) =>
    import(
        /* webpackChunkName: "SearchResultsAsync" */ '../screens/search-results'
    ).then(({ fetchData, SearchResults }) => ({
        fetchData: () => fetchData(context),
        Screen: SearchResults,
        screenName: SEARCH_RESULTS_SCREEN
    }))

const routes = {
    path: '/',
    children: [
        {
            path: '',
            action: (context) =>
                import(
                    /* webpackChunkName: "HomeAsync" */ '../screens/home'
                ).then(({ fetchData, Home }) => ({
                    fetchData: () => fetchData(context),
                    Screen: Home,
                    screenName: HOME_SCREEN
                }))
        },
        {
            path: '/meals',
            children: [
                {
                    path: '/:prettyUrl?/',
                    action: searchResultsAction
                },
                {
                    path: '/:prettyUrl?/list-:page',
                    action: searchResultsAction
                },
            ]

        },
        {
            path: '/meal',
            children: [
                {
                    path: '/:slug',
                    children: [
                        {
                            path: '',
                            action: (context) =>
                                import(
                                    /* webpackChunkName: "MealDetailsAsync" */ '../screens/meal-details'
                                ).then(({ fetchData, MealDetails }) => ({
                                    fetchData: () => fetchData(context),
                                    Screen: MealDetails,
                                    screenName: MEAL_DETAILS_SCREEN
                                }))
                        }
                    ]
                },
            ]

        },
        {
            path: '/restaurants',
            children: [
                {
                    path: '/:prettyUrl?/',
                    action: searchResultsAction
                },
                {
                    path: '/:prettyUrl?/list-:page',
                    action: searchResultsAction
                },
            ]

        },
        {
            path: '/restaurant',
            children: [
                {
                    path: '/:slug',
                    children: [
                        {
                            path: '',
                            action: (context) =>
                                import(
                                    /* webpackChunkName: "RestaurantDetailsAsync" */ '../screens/restaurant-details'
                                ).then(({ fetchData, RestaurantDetails }) => ({
                                    fetchData: () => fetchData(context),
                                    Screen: RestaurantDetails,
                                    screenName: RESTAURANT_DETAILS_SCREEN
                                }))
                        }
                    ]
                },
            ]

        },
        notFoundRoute
    ]
};

export default routes;
