import { paletteHelper } from "../../utils";
import forwardRef from "../../private/forwardRef";
import React from "react";
import styled from "styled-components";

type BoxProps = {
    borderRadius: CardRadius
}

const Box = styled.div<BoxProps>`
    box-shadow: ${({ theme }) => theme.shadow};
    border-radius: ${({ theme, borderRadius }) =>
        theme.border.radius[borderRadius]};
    background-color: ${paletteHelper("backgroundPrimaryLow")};
`

const defaultProps = {
    borderRadius: "large" as CardRadius
}

const Card = forwardRef<CardProps, "div">((props, ref) => {
    const { children, ...rest } = { ...defaultProps, ...props }

    return (
        <Box ref={ref} {...rest}>
            {children}
        </Box>
    )
})

Card.displayName = "Card";

export default Card;