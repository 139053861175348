import React, { FC } from "react";
import styled from 'styled-components';
import { Button, CrossLg, paletteHelper, spacingHelper } from "../../../../@nitty";
import { generateSearchResultUrl, Channel, SearchQuery } from "../../../../@nearbites.com/query";
import { SuggestionBox } from "../suggestions";
import { dangerouslySetClassNames, modalSuggestionBoxStyles, containerClassName } from "../suggestions/styles";
import { fetchingData } from '../../../../util/appEvents';

export type SearchProps = {
    closeModal: boolean;
    channels: Array<Channel>;
    searchQuery: SearchQuery,
    onSubmitSearch: (searchQuery: SearchQuery) => void;
    onSearchQueryUpdate?: (searchQuery: SearchQuery) => void;
}

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    ${modalSuggestionBoxStyles}
`;

const StyledButton = styled(Button).attrs({
    sizeVariant: "medium"
})`

    border-radius: 1.5rem;
    padding: 0.75rem;
    height: 3rem;

    svg {
        width: 1.375rem;
        height: 1.375rem;
    }

    &:focus::after {
        border-radius: 2rem;
        top: 0rem;
        right: 0rem;
        bottom: 0rem;
        left: 0rem;
    }
`;

const BackButton = styled(StyledButton)`
    position: absolute;
    z-index: 1;

    top: ${spacingHelper("extraSmall")};
    right: ${spacingHelper("extraSmall")};

    color: ${paletteHelper("textSecondary")};
    background-color: ${paletteHelper("backgroundHoveredPrimary")};

    :hover {
        background-color: ${paletteHelper("borderSecondary")};
    }
`;

const SearchButton = styled(StyledButton)`
`;

const ContentWrapper = styled.div`
    flex: 1;
`;

const Actions = styled.div`
    padding: 1rem;
    position: sticky;
    bottom: 0px;
    z-index: 999;
    background: #fff;
    border-top: 0.0625rem solid ${paletteHelper("borderSecondary")};
`;

const Search: FC<SearchProps> = ({
    closeModal,
    searchQuery,
    onSuggestionChange,
    onSubmitSearch,
    onSearchQueryUpdate,
    selectedOptions
}) => {
    const {channel} = searchQuery;

    const onSearchButtonClick = () => {
        fetchingData.emit('start');
        window.location.href = generateSearchResultUrl(searchQuery);
        closeModal();
    }

    return (
        <Wrapper>
            <BackButton
                hideLabel
                icon={<CrossLg />}
                onClick={closeModal}
            >
                Close
            </BackButton>
            <ContentWrapper>
                <SuggestionBox
                    className={containerClassName}
                    dangerouslySetClassNames={dangerouslySetClassNames}
                    hideDropdown={() => {}}
                    channel={channel}
                    onSelectedOptionsChange={onSuggestionChange}
                    selectedOptions={selectedOptions}
                />
            </ContentWrapper>
            <Actions>
                <SearchButton
                    fullWidth
                    onClick={onSearchButtonClick}
                >
                    Search
                </SearchButton>
            </Actions>
        </Wrapper>
    )
}

export default Search;
