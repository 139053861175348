import React from "react";
import { Button, SearchMd } from "../../../../@nitty";
import styled from "styled-components";

const StyledButton = styled(Button).attrs({
    sizeVariant: "medium"
})`
    border-radius: 1.5rem;
    padding: 0.75rem;
    height: 3rem;

    svg {
        width: 1.375rem;
        height: 1.375rem;
    }

    &:focus::after {
        border-radius: 2rem;
        top: 0rem;
        right: 0rem;
        bottom: 0rem;
        left: 0rem;
    }
`;

const SearchButton = ({ onClick }) => {
    return (
        <StyledButton
            hideLabel={true}
            icon={<SearchMd />}
            onClick={onClick}
        />
    )
};

export default SearchButton;
