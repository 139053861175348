import forwardRef from "../../private/forwardRef";
import React from "react"
import { replaceToken } from "../../private/helpers";
import styled from "styled-components";
import { NittyTheme } from "../nitty/types";
import { Container } from "./styles";
import { SkeletonTextProps } from "./skeleton.types";

const defaultProps = {
    borderRadius: "small",
    gap: "small",
    height: "body01",
    width: "100%",
    numOfLines: 1,
}

const heightHelper = (theme: NittyTheme, height: string) => {
    const typographyVariant = 
        theme.typography[height as keyof typeof theme.typography]
    return typographyVariant
        ? typographyVariant.lineHeight
        : replaceToken(theme.spacing, height)
}

interface SkeletonContainerProps {
    theme: NittyTheme
    height: string
    lineWidth: string
}

const SkeletonContainer = styled(Container)<SkeletonContainerProps>`
    height: ${({ theme, height }) => heightHelper(theme, height)};
    width: ${({ lineWidth }) => lineWidth};
`

interface BoxProps {
    theme: NittyTheme
    gap: string
    width: string
}

const SkeletonTextBox = styled.div<BoxProps>`
    display: flex;
    flex-direction: column;
    width: ${({ theme, width }) => replaceToken(theme.spacing, width)};
    & > *:not(:last-child) {
        margin-bottom: ${({ theme, gap }) => replaceToken(theme.spacing, gap)};
    }
`

const SkeletonText = forwardRef<SkeletonTextProps, "div">(
    (props, externalRef) => {
        const {
            borderRadius,
            gap,
            height,
            numOfLines,
            width,
            className,
            style,
            dangerouslySetClassNames,
            ...otherProps
        } = {
            ...defaultProps,
            ...props
        }

        return (
            <SkeletonTextBox gap={gap} width={width} className={className}>
                {Array.from({ length: numOfLines }, (_, i) => (
                    <SkeletonContainer
                        className={dangerouslySetClassNames?.skeletonTextContainer}
                        key={i}
                        ref={externalRef}
                        borderRadius={borderRadius}
                        lineWidth={numOfLines > 1 && i === numOfLines - 1 ? "80%" : "100%"}
                        height={height}
                        {...otherProps}
                    />
                ))}
            </SkeletonTextBox>
        )
    }
)

SkeletonText.displayName = "SkeletonText"

export default SkeletonText;