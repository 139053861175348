import { replaceToken } from "../../private/helpers";
import styled, { keyframes } from "styled-components"
import { NittyTheme } from "../nitty/types"

const radiusHelper = (borderRadius: string) => 
({ theme }: { theme: NittyTheme }) => {
    if (borderRadius === "circular") return `50%`
    return replaceToken(theme.border.radius, borderRadius) 
}

interface ContainerProps {
    theme: NittyTheme,
    borderRadius: string
}

const waveKeyframe = keyframes`
    0% {
        background-position: 200%;
    }
    100% {
        background-position: 0%;
    }
`

export const Container = styled.div<ContainerProps>`
    border-radius: ${({ borderRadius }) => radiusHelper(borderRadius)};
    background-image: ${({ theme }) =>
    `linear-gradient(to right, ${theme.palette.backgroundSecondary}, ${theme.palette.backgroundSecondaryBase}, ${theme.palette.backgroundSecondary})`};

    @media (prefers-reduced-motion: no-preference) {
        background-size: 200%;
        animation: ${waveKeyframe} 3s linear 0.5s infinite;
    }
`