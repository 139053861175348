import React, { useCallback, useReducer } from 'react';
import appLogger from '../util/logging/appLogger';
import Root from './Root';
import { Error as ErrorScreen } from '../screens/error'
import useCurrentScreen from '../router/useCurrentScreen';
import { fetchingData } from '../util/appEvents';
import { AppProvider } from '../util/AppContext';
import { SearchContext } from '../util/search/useSearch';
import createRouterLocation from '../router/createLocationFromHistoryLocation';
import { parseSearchUrl } from '../@nearbites.com/query';
import { SearchQueryReducer } from '../util/search';

function Screen({ history, initialView, router, store }) {
    const onLocationChange = useCallback(
        action => {
            if(action !== 'POP') {
                window.scrollTo(0,0);
            }
        }, []);
    const onTransition = (type) => () => fetchingData.emit(type);
    const [error, { Screen, pageData, screenName}, location] = useCurrentScreen(
        router,
        history,
        initialView,
        store,
        onLocationChange,
        onTransition('start'),
        onTransition('end')
    );
    const routerLocation = createRouterLocation(history.location);

    let initialSearchQuery;
    try {
        initialSearchQuery = parseSearchUrl(routerLocation.pathname + routerLocation.search)
    } catch (error) {
        initialSearchQuery = parseSearchUrl("/meals/")
    }

    const [searchQuery, updateSearchQuery] = useReducer(
        SearchQueryReducer,
        initialSearchQuery
    )

    if(error) {
        appLogger.error(error.message)(error);
    }

    return (
        <AppProvider
            initialValue={{
                location: routerLocation
            }}
        >
            <SearchContext.Provider value={{ searchQuery, updateSearchQuery }}>
                <Root screenName={screenName} history={history}>
                    {!error && !!Screen && <Screen data={pageData} location={location} />}
                    {!!error && <ErrorScreen error={500} />}
                </Root>
            </SearchContext.Provider>
        </AppProvider>
    )
}

export default Screen;
