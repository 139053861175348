import { IS_CLIENT } from '../../../../config/environment';

function handleLastResortError(error) {
    console.log('failed to resolve logger');
    console.error(error);
}

class Logger {
    logger;
    constructor() {
        if(IS_CLIENT) {
            this.logger = import(
                /* webpackChunkName: "client-logger" */ '../../client/logging/clientLogger'
            ).then(importedModule => importedModule.clientLogger);
        }else{
            this.logger = import(
                /* webpackChunkName: "server-logger" */ '../../server/logging/createServerLogger'
            ).then(importedModule => importedModule.default());
        }
    }
    error = (message, customAttributes) => (error) => {
        this.logger.then(l => l.error(message, customAttributes)(error)).catch(handleLastResortError);
    }
    warn = (...args) => {
        this.logger.then(l => l.warn(...args)).catch(handleLastResortError);
    }
    log = (...args) => {
        this.logger.then(l => l.log(...args)).catch(handleLastResortError);
    }
}

export default new Logger();
