import React, {FC, useEffect, useState} from "react";
import styled from "styled-components";
import { GpsMd, MultiAutocomplete, P, SearchMd, ListboxOption, spacingHelper, Strong } from "../../../../@nitty";
import { ChannelType } from "../../../../@nearbites.com/query";
import { SUGGEST_API } from '../../../../../../config/environment';

type SuggestionOption = {
    display: {
        text: string
    },
    type: string
}

type SuggestionsParams = {
    query: string;
}

type SuggestionsResponse = {
    suggestions: SuggestionOption[];
}

type SuggestionProps = {
    hideDropdown: () => void;
    onSelectedOptionsChange: () => void;
    selectedOptions: SuggestionOption[];
    placeholder: string;
    label: string;
}

const AUTOCOMPLETE_LABEL = "Keywords"
const AUTOCOMPLETE_PLACEHOLDER = "Meals, Restaurants, Location"
const MAX_OPTIONS = 7;
const MEAL_SUGGESTION_TYPES = "location,tag";
const RESTAURANT_SUGGESTION_TYPES = "restaurant,location,tag";

const StyledListboxOption = styled(ListboxOption)`
    display: flex;
    align-items: center;

    svg {
        position: relative;
        top: -0.0625rem;
        margin-right: ${spacingHelper("extraSmall")};
        flex-shrink: 0;
    }

    p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: elipsis;
    }
`;

const getIconType = (type: string) => {
    switch (type) {
        case "location":
            return <GpsMd />
        case "tag":
            return null;
        default:
            return <SearchMd />
    }
};

const mapOptionToListNode = ({
    option,
    inputValue,
    isActive,
    mapOptionToString
}) => {
    const optionText = mapOptionToString(option);
    const inputStart = optionText.toLowerCase().indexOf(inputValue.toLowerCase());
    const containsInputValue = inputValue && inputStart !== -1;

    const icon = getIconType(option.type);

    return (
        <StyledListboxOption isActive={isActive} sizeVariant="large">
            {icon}
            <P variant="body01">
                {containsInputValue ? (
                    <>
                        {optionText.substr(0, inputStart)}
                        <Strong>{optionText.substr(inputStart, inputValue.length)}</Strong>
                        {optionText.substr(inputStart + inputValue.length)}
                    </>
                ) : (
                    <>{optionText}</>
                )}
            </P>
        </StyledListboxOption>
    )
}

const mapOptionToString = (option) => option.display.text;

const fetchSuggestions = ({ query, types }: SuggestionsParams) => {
    const params = [
        ["max", MAX_OPTIONS],
        ["type", types],
        ["query", query]
    ]
    const url = `${SUGGEST_API}?${new URLSearchParams(params).toString()}`;

    return fetch(url)
        .then(response => response.json())
        .then((data: SuggestionsResponse) => data.suggestions);
}

const SuggestionBox: FC<SuggestionProps> = ({
    hideDropdown,
    channel,
    ...props
}) => {
    const [suggestions, setSuggestions] = useState<string[]>([])
    const suggestionTypes = channel === ChannelType.MEALS ? MEAL_SUGGESTION_TYPES : RESTAURANT_SUGGESTION_TYPES;

    const keyHandler = (e: KeyboardEvent) => e.key === "Escape" && hideDropdown()

    const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") hideDropdown();
    }

    const handleOnBlur = (event: FocusEvent) => {
        const focusedElement = event.relatedTarget;
        const component = event.currentTarget;

        const isInsideComponent = component.contains(focusedElement);

        if(!isInsideComponent) {
            hideDropdown()
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", keyHandler);

        setTimeout(() => {
            window.addEventListener("click", hideDropdown)
        }, 0);

        return () => {
            window.removeEventListener("keydown", keyHandler);
            window.removeEventListener("click", hideDropdown);
        }
    }, [])

    const onInputChange = (inputValue: string) => {
        const params = {
            types: suggestionTypes,
            query: inputValue
        }

        fetchSuggestions(params)
            .then((fetchedSuggestions: SuggestionOption[]) => {
                const updatedSuggestions =
                    inputValue !== ""
                        ? [
                            ...fetchedSuggestions,
                            {
                                display: {
                                    text: inputValue,
                                },
                                type: 'keyword'
                            }
                        ] : fetchedSuggestions;

                setSuggestions(updatedSuggestions);
            })
    }

    return (
       <div
            onClick={(event) => {
                event.stopPropagation();
            }}
            onBlur={handleOnBlur}
        >
            <MultiAutocomplete
                {...props}
                placeholder={AUTOCOMPLETE_PLACEHOLDER}
                label={AUTOCOMPLETE_LABEL}
                hideLabel
                autoFocus
                openOnFocus
                options={suggestions}
                onInputChange={onInputChange}
                onKeyPress={onKeyPress}
                mapOptionToListNode={mapOptionToListNode}
                mapOptionToString={mapOptionToString}
                disableListboxFlip
            />
        </div>
    )
}

export default SuggestionBox;
