import { SortType, Options } from "../types"
import {
    mealTypes
} from '../meal-types';

const mealSortTypes: Array<SortType> = [
    {
        value: 'relevance',
        urlValue: 'relevance',
        label: 'Most relevant'
    }
]

const priceOptions: Options = [
    {
        value: "any",
        label: "Any",
        abbreviation: "Any"
    },
    {
        value: "10",
        label: "$10",
    }
]

export { 
    priceOptions,
    mealSortTypes,
    mealTypes
}