import React, { Component } from 'react';
import Helmet from 'react-helmet';

const NotFoundError = (
    <p>NotFoundError</p>
);

const ServerError = (
    <p>ServerError</p>
);

export default class Error extends Component {
    static displayName = 'error';

    getMetaData() {
        const title = 'Nearbites';
        const meta = [{ name: 'robots', content: 'noindex, nofollow' }];
        return {
            title,
            meta
        }
    }

    render() {
        let errorMessage,  errorStatus;
        const status = this.props.error;

        if(status == 404 || !status) {
            errorStatus = '404';
            errorMessage = NotFoundError;
        }else{
            errorStatus = '500';
            errorMessage = ServerError;
        }

        const { title, meta } = this.getMetaData();

        return (
            <div>
                <Helmet title={title} meta={meta}/>
                <h2>Oh no!</h2>
                {errorMessage}
                <p>{errorStatus}</p>
            </div>
        );
    }
}
