import { paletteHelper } from "../../utils";
import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

const ReactModalWrapper = ({
    className,
    portalClassName,
    ...reactModalProps
}: ReactModal.Props) => (
    <ReactModal
        portalClassName={
            [portalClassName, className].filter(Boolean).join(" ") || undefined
        }
        {...reactModalProps}
    />
);

const AnimatedModal = styled(ReactModalWrapper)`
    & .ReactModal__Overlay {
        background-color: rgba(36, 34, 38, 0);
        transition: background-color 200ms;

        &--after-open {
            background-color: ${paletteHelper("backgroundOverlayStatic")};
        }
        &--before-close {
            background-color: rgba(36, 34, 38, 0);
        }
    }
    & .ReactModal__Content {
        opacity: 0.01;
        transform: scale(0.95);
        transition-property: opacity, transform;
        transition-duration: 200ms;
        &--after-open {
            opacity: 1;
            transform: scale(1);
        }
        &--before-close {
            opacity: 0;
            transform: scale(0.95);
        }
    }
`

export { AnimatedModal }