import { useEffect, useState } from "react";
import { debounce } from '../private/helpers';

export const useInnerHeight = () => {
    const [innerHeight, setInnerHeight] = useState("unset");

    useEffect(() => {
        const updateInnerHeight = () => {
            setInnerHeight(`${window.innerHeight}px`)
        }
        updateInnerHeight();

        const { debounceFunc, cancel } = debounce(updateInnerHeight);
        window.addEventListener('resize', debounceFunc);

        return () => {
            window.removeEventListener('resize', debounceFunc);
            cancel();
        }
    }, []);

    return innerHeight;
}