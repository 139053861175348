import { SearchQuery } from "../../@nearbites.com/query";
import {createContext, Dispatch, useContext} from "react";
import { SearchQueryAction } from "./types";

type SearchContext = {
    searchQuery: SearchQuery,
    updateSearchQuery: Dispatch<SearchQueryAction>;
}

const SearchContext = createContext<SearchContext>({} as SearchContext);

const useSearch = () => useContext(SearchContext);

export { SearchContext };

export default useSearch;