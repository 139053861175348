import React, { useState } from "react";
import styled from 'styled-components';
import SuggestionBox from "./suggestion-box";
import SuggestionButton from "./suggestion-button";
import { dangerouslySetClassNames, suggestionBoxStyles, containerClassName } from "./styles";

const Wrapper = styled.div`
    ${suggestionBoxStyles}
`;

const Suggestions = ({
    selectedOptions,
    channel,
    onChange,
}) => {
    const [showKeywordSuggest, setShowKeywordSuggest] = useState(false);

    const showSuggest = () => setShowKeywordSuggest(true);
    const hideSuggest = () => setShowKeywordSuggest(false);

    return (
        <Wrapper>
            {showKeywordSuggest ? (
                <SuggestionBox
                    className={containerClassName}
                    dangerouslySetClassNames={dangerouslySetClassNames}
                    hideDropdown={hideSuggest}
                    channel={channel}
                    onSelectedOptionsChange={onChange}
                    selectedOptions={selectedOptions}
                />
            ) : (
                <SuggestionButton
                    onClick={showSuggest}
                    selectedOptions={selectedOptions}
                />
            )}
        </Wrapper>
    )
}

export default Suggestions;
