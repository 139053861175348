import React, { FC, ReactElement, ReactNode } from "react"
import styled from "styled-components";
import { paletteHelper, spacingHelper } from "../../../@nitty";
import NearbitesLogo from "./NearbitesLogo";
import headerHeights from "./headerHeights";

const HeaderWrapper = styled.header`
    background: ${paletteHelper("backgroundPrimary")};
    height: ${headerHeights.medium};
    position: sticky;
    top: 0;
    z-index: 10;
    border-bottom: 1px solid #e9e9e9;
`;

const HeaderContent = styled.div`
    margin: auto;
    display: flex;
    padding: ${spacingHelper("0 medium")};

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        max-width: calc(
            ${({ theme }) => theme.maxWidth} + ${spacingHelper("medium")}
        );
        grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
        padding: ${spacingHelper("0 extraLarge")};
        margin: ${spacingHelper('0 auto')};
    }
`;

const LogoWrapper = styled.div`
    display: flex;
`

const SearchWrapper = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex: 1;
`

const MenuWrapper = styled.div`
`

const Header: FC<{
    children?: ReactNode;
    searchHeader?: ReactElement;
}> = ({
    searchHeader
}) => {
    return (
        <HeaderWrapper>
            <HeaderContent>
                <LogoWrapper>
                    <NearbitesLogo />
                </LogoWrapper>
                <SearchWrapper>
                    {searchHeader}
                </SearchWrapper>
                <MenuWrapper>
                    {/* <div>menu</div> */}
                </MenuWrapper>
            </HeaderContent>
        </HeaderWrapper>
    )
}

export default Header;
