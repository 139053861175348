import React from "react"
import styled from "styled-components";
import { paletteHelper, spacingHelper, Text, typographyHelper } from "../../../@nitty";

const FooterWrapper = styled.footer`
    background: ${paletteHelper("backgroundHoveredPrimary")};
`;

const FooterContent = styled.div`
    max-width: calc(
        ${({ theme }) => theme.bodyMaxWidth} + ${spacingHelper("medium")}
    );
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${spacingHelper("medium medium")};

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        padding: ${spacingHelper("large extraLarge")};
    }
`;

const Copyright = styled(Text).attrs(() => ({
    as: 'div'
}))`
    display: inline-block;
    color: ${paletteHelper("textSecondary")};
    ${typographyHelper("body02")}
    margin-right: 2rem;
`;

const SiteSections = styled.div`
    ol {
        ${({ theme }) => theme.layoutBreakpoints.medium} {
            display: inline;
        }
        
        li {
            display: inline-block;
            ${typographyHelper("body02")}
            
            &+li {
                margin-left: 1rem;
            }
            
            a {
                text-decoration: none;
                color: ${paletteHelper("textPrimary")};

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
`

const Social = styled.div`
    display: none;
`

const Footer = () => {
    const year = new Date().getFullYear()
    
    return (
        <FooterWrapper>
            <FooterContent>
                <SiteSections>
                    <Copyright>&copy; {`${year}`}, Nearbites Pty Ltd.</Copyright>
                    <ol>
                        <li><a href="/privacy/">Privacy</a></li>
                        <li><a href="/terms/">Terms</a></li>
                        <li><a href="/sitemap/">Sitemap</a></li>
                    </ol>
                </SiteSections>
                <Social>
                    Social
                </Social>
            </FooterContent>
        </FooterWrapper>
    )
}

export default Footer;