import { Button, paletteHelper } from "../../@nitty";
import styled, { css } from "styled-components";

type PillButtonProps = {
    readonly selected?: boolean;
}

const Pill = styled(Button).attrs({
    sizeVariant: "medium"
})<PillButtonProps>`
    border-radius: 1.5rem;
    padding 0.375rem;

    color: ${paletteHelper("textSecondary")};
    background-color: ${paletteHelper("backgroundHoveredPrimary")};

    :hover {
        background-color: ${paletteHelper("borderSecondary")};
    }

    &:focus::after {
        border-radius: 2rem;
        top: 0rem;
        right: 0rem;
        bottom: 0rem;
        left: 0rem;
    }

    ${(props) =>
        props.selected
            ? css`
                border-radius: 2rem;
                border: 0.0625rem solid var(--nitty-borderPrimary);
            `
            : css`
                background-color: ${paletteHelper("backgroundHoveredPrimary")};
            `
    }
`;

export default Pill;
