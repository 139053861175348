import React from "react";
import styled from "styled-components";
import { SpriteProps, SpriteSize } from "./sprite.types"
import forwardRef from '../../private/forwardRef';

const defaultProps = {
    sizeVariant: "medium" as SpriteSize
}

type StyledSvgProps = {
    sizeVariant: SpriteSize
}

const StyledSvg = styled.svg<StyledSvgProps>`
    width: ${({ theme, sizeVariant, width }) => width || theme.spacing[sizeVariant]};
    height: ${({ theme, sizeVariant, height }) => height || theme.spacing[sizeVariant]};
`

const Sprite = forwardRef<SpriteProps, "svg">((props, ref) => {
    const { iconId, sizeVariant, spriteSheetId, ...remainingProps } = {
        ...defaultProps,
        ...props
    }

    const id = spriteSheetId ? `${spriteSheetId}}-${iconId}` : iconId;

    return (
        <StyledSvg sizeVariant={sizeVariant} ref={ref} {...remainingProps}>
            <use href={`#${id}`}></use>
        </StyledSvg>
    )
})

Sprite.displayName = "Sprite";

export default Sprite;